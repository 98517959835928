Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "loyaltysystem";
exports.labelBodyText = "loyaltysystem Body";

exports.btnExampleTitle = "CLICK ME";

exports.getRewardPoint = "bx_block_reward/rewards/current_point_redeem";
exports.getSolutionRewardPoint = "bx_block_reward/rewards/solution_rewards";
exports.pachRedeemStatus = "bx_block_reward/rewards/update_redeem_status";
exports.claimsData = "bx_block_solutions/solutions";
exports.getSubCategoryAPIEndPoint = "bx_block_categories/categories";
exports.termsAndCondition = "bx_block_settings/settings/terms_and_condition";

exports.point_earned = "Point Earned";
exports.RewardsValue = "Rewards Value";
exports.currency_sign = "$";
exports.Redeem = "Redeem";
exports.Rewards = "Rewards";
exports.Changenow = "Change now";
// Customizable Area End