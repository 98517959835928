export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const star = require("../assets/star.png");
export const big_star = require("../assets/big_star.png");
export const boxIcon = require("../assets/icon.png");
export const yellowIcon = require("../assets/yellow_box.png");
export const orangeIcon = require("../assets/orange_box.png");
export const rightArrow = require("../assets/right_arrow.png");

export const cubeImage = require("../assets/cubeImg.png");