Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.getCategoryAPIEndPoint = "bx_block_categories/categories";
exports.getSubCategoryAPIEndPoint = "bx_block_categories/categories";
exports.getAdditionalQuestion = "bx_block_question/questions";
exports.getAdditionalQuestionSubmit = "account_questions";
exports.postClaimData = "bx_block_claims/claims";
exports.postCommitToSolution = "bx_block_commit/commits/commit_to_solution";
exports.createCommit = "bx_block_commit/commits";
exports.postRewardRegistration = "bx_block_reward/rewards";
exports.claimsStatus = "bx_block_claims/claims";
exports.claimsData = "bx_block_solutions/solutions";
exports.termsAndCondition = "bx_block_settings/settings/terms_and_condition";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";

exports.pick_your_focus_area="Pick your focus area";
exports.choose_the_areas="Choose the areas most applicable to you.";
exports.sort_by=" Sort by :";
exports.view_details="View details";
exports.change_now="Change Now";
exports.you_will_earn="You will earn:";
exports.read_more="Read more";
exports.read_less="Read less";
exports.commit_to_solution="Commit to Solution";
exports.report_progress="Report Progress";

exports.sucess="Success !";
exports.sucess_message="Proof has been successfully submitted, please wait as we review it. Your rewards are on the way!";
exports.report_progress="Report Progress";
exports.upload_message="Upload the proof of action to claim your rewards.";
exports.upload_file="Upload file!"


exports.cancel="Cancel"
exports.submit="Submit"
exports.upload_file="Upload file!"
exports.upload_file="Upload file!"

exports.additional_que="Additional Questions"
exports.form_desc="To help get a more precise measurement of the impact of your actions, we have few additional questions."
exports.loading_message="Please wait while your file is being uploaded"

// Customizable Area End