import React from "react";

// Customizable Area Start
import { Box, Typography, CardContent } from "@material-ui/core";
import TermsAndConditionController, {
  Props
} from "./TermsAndConditionController.web"
import Card from "@material-ui/core/Card";
import { styled } from "@material-ui/styles";
import {
  back
} from "./assets";
// Customizable Area End

//Customizable Area Start
const CardBlock = styled(Box)({
  backgroundColor: "#1B122E",
  "& .cardContent": {
    padding: "24px",
    marginTop: "24px",
    borderRadius: "24px",
    backgroundColor: "#261E38",
    "@media (max-width:600px)": {
      margin:"10px 0px",
      fontSize:"25px"
    }
  },
  "& .terms-and-condition": {
    fontSize:"30px",
    fontWeight: 400,
    fontFamily: "Figtree, sans-serif",
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize:"25px"
    }
  },
  "& .description": {
    fontSize:"16px",
    fontWeight: 400,
    fontFamily: "Figtree, sans-serif",
    color: "#FAFAFA"
  },
  "& .no-data-found": {
    fontFamily: "Figtree, sans-serif",
    marginTop: "40vh",
    fontSize: "20px",
    fontWeight: 500,
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "14px"
    }
  }
});

const Backblog = styled(Box)({
  "& .back-logo": {
    display:"none",
    "@media (max-width:600px)": {
     display:"flex",
     justifyContent: "left",
     margin: "24px", 
    },
  }

});

//Customizable Area End

export default class TermsAndCondition extends TermsAndConditionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Required for all blocks
      <Box height="100%">
        <Backblog>
          <Box className="back-logo">
            <img 
            src={back}
            alt="back"
            data-test-id="back-btn"
            onClick={this.handleNavigateSignup}/>
          </Box>
        </Backblog>
        <Box bgcolor="#1B122E" display="flex" alignItems="center" justifyContent="center" margin="30px">      
          <CardBlock
            sx={{
              alignItems: "center"
            }}
            bgcolor="#1B122E"
            color="#FCFCFC"
          >
            {this.state.dataCard?.description.length ? (
              <Box>
                <Typography className="terms-and-condition">{this.state.dataCard.title}</Typography> 
                  <Card className="cardcontent">
                    <CardContent>
                      <Box>
                        <Typography className="description" dangerouslySetInnerHTML={{ __html: this.state.dataCard.description }} />
                      </Box>
                    </CardContent>
                  </Card>
              </Box>
            ) : (<Typography className="no-data-found">No Data available</Typography>)}
          </CardBlock>       
        </Box>
      </Box>
    );
  }
}
