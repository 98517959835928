import React from 'react';
import { styled } from "@material-ui/styles";
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';

type Column = {
    id: string,
    numeric?: boolean,
    disablePadding?: boolean,
    label: string
};

type Order = "desc" | "asc";

type MyProps = {
    columns: Column[],
    rows: any[],
    rowsPerPage: number,
    rowsPerPageOptions: number[]
    align: "left" | "right" | "inherit" | "center" | "justify" | undefined
}

type MyHeadProps = {
    columns: Column[],
    order: Order,
    orderBy: string,
    onRequestSort: void | any,
    align: "left" | "right" | "inherit" | "center" | "justify" | undefined
}

type MyState = {
    order: Order,
    orderBy: string,
    data: any[],
    page: number,
    rowsPerPage: number,
}

function desc(a: any, b: any, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array: any[], cmp: any) {
    const stabilizedThis = array.map((el: any, index: number) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order: Order, orderBy: string) {
    return order === 'desc' ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
}

const TableStyle = styled('div')({
    '& .MuiTableCell-root': {
        color: '#FFFFFF',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        padding: '11px',
        borderColor: 'rgba(166, 151, 187, 0.3)',
        fontFamily: "'Figtree', sans-serif",
        '&.MuiTableCell-head, & .MuiTableSortLabel-root': {
            color: '#A697BB !important',
            fontWeight: '600',
            '& .MuiSvgIcon-root':{
                color: '#A697BB !important',
            }
        }
    },
    '& .total': {
        '& .MuiTableCell-root': {
            padding: '30px 0',
            fontWeight: '600',
            fontSize: '16px',
            border: 'none'
        }
    },
    '& .MuiTablePagination-root': {
        color: '#ffffff'
    },
    '& .MuiButtonBase-root': {
        '&.Mui-disabled': {
            color: 'rgba(238, 238, 238, 0.26)'
        }
    },
    '& .MuiSelect-icon': {
        color: '#ffffff'
    }
})

class EnhancedTableHead extends React.Component<MyHeadProps> {
    constructor(props:MyHeadProps){
        super(props);
        this.createSortHandler = this.createSortHandler.bind(this)
    }
    createSortHandler = (property: string) => {
        this.props.onRequestSort(property);
    };

    render() {
        const { order, orderBy, columns, align } = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell
                        key={'No'}
                        align={align}
                        padding={'normal'}
                        sortDirection={orderBy === "No" ? order : false}
                    >
                        <Tooltip
                            title="Sort"
                            placement={'bottom-start'}
                            enterDelay={300}
                        >
                            <TableSortLabel
                                active={orderBy === "No"}
                                direction={order}
                                onClick={() => this.createSortHandler("No")}
                            >
                                No
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                    {columns.map(
                        column => (
                            <TableCell
                                key={column.id}
                                align={column.numeric ? 'right' : align}
                                padding={column.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === column.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={() => this.createSortHandler(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

class DataTable extends React.Component<MyProps, MyState> {
    
    constructor(props: MyProps) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: '',
            data: this.props.rows,
            page: 0,
            rowsPerPage: this.props.rowsPerPage,
        }
        this.handleRequestSort = this.handleRequestSort.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    }

    handleRequestSort = (property: string) => {
        const orderBy = property;
        this.setState({ order: (this.state.orderBy === property && this.state.order === 'desc') ? 'asc' : 'desc', orderBy });
    };

    handleChangePage = (event: any, page: number) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const {  order, orderBy, rowsPerPage, page } = this.state;
        const data= this.props.rows
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
            <TableStyle>
                <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        columns={this.props.columns}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        align={this.props.align}
                    />
                    <TableBody>
                        {stableSort(data, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((data: any, index: number) => {
                            if(data.scop3Cat.length > 0){
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        <TableCell align={this.props.align}>{index + 1}</TableCell>
                                        {Object.keys(data).map((key,index) => <TableCell align={this.props.align} key={key}>
                                            {Array.isArray(data[key])
                                            ? data[key].map((item:any, subIndex:any) => (
                                                <div key={subIndex}>{item}</div>
                                                ))
                                            : data[key]}
                                        </TableCell>)}
                                    </TableRow>
                                );
                            }
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={this.props.rowsPerPageOptions}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </TableStyle>
        );
    }
}


export default DataTable;
