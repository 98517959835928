import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | null;
  commingSoonArray: any;
  dataCard: {id:string, type:string, attributes:{id:number, image_url: string, name:string  }}[];
  hasData: boolean;
  currentPage: number;
  getCategoryError: string;
  contact_us: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCategoryById: string = "";
  getCategoryPagesById: string = "";
  getContactUs: string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      commingSoonArray: [],
      dataCard: [],
      hasData: false,
      currentPage: 1,
      getCategoryError:"",
      contact_us: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    const token = localStorage.getItem("user_token");
    this.setState({token:token});
    this.getCategoryData();
    this.handleContactUs();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.callAfterApiCall(apiRequestCallId, responseJson);
    }
    // Customizable Area Start
    // Customizable Area End
  }

  callAfterApiCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCategoryById) {
      if (!responseJson.error) {
        this.setCategory(responseJson.data);
        return responseJson;
      } else {
        this.setState({getCategoryError:responseJson.error});
        this.parseApiErrorResponse(responseJson);
      }
    } else if (apiRequestCallId === this.getCategoryPagesById) {
      if (!responseJson.error) {
        this.getPageCategory(responseJson.data);
      }else{
        this.setState({getCategoryError:responseJson.error});
      }
    }else if (apiRequestCallId === this.getContactUs ) {
      if (!responseJson.error) {
        const contactus = responseJson.filter((data: any) => {
          return data.title === "Contact Us";
        });
        this.setState({ contact_us: contactus[0].description });  
      }
    }
  };

  // Customizable Area Start

  setCategory = (data: {id:string, type:string, attributes:{id:number, image_url: string, name:string  }}[]) => {
    const hasData = data.length >= 15;
    this.setState({ dataCard: data, hasData: hasData });
  };

  handleCategoriesData = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.getCategoryPagesById = sendAPIRequest(
      configJSON.getCategoryAPIEndPoint +
        `?page=${this.state.currentPage}&per_page=15`,
      {
        headers: {
          token: this.state.token,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );
  };

  getPageCategory = (data: {id:string, type:string, attributes:{id:number, image_url: string, name:string  }}[]) => {
    const hasData = data.length < 15 ? false : true;
    const catData = this.state.dataCard?.length ? this.state.dataCard : [];
    this.setState({ dataCard: [...catData, ...data], hasData: hasData });
  };

  getCategoryData = () => {
    this.getCategoryById = sendAPIRequest(
      configJSON.getCategoryAPIEndPoint + "?page=1&per_page=15",
      {
        headers: {
          token: this.state.token,
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );
  };

  handleSubCategoryModal = (type: string, name: string, id: string) => {
    this.props.history.push({
      pathname: type,
      state: {
        breadCrum: [
          { name: "Focus area", path: "Categories" },
          { name: name, path: "SubCategories", id:id },
        ],
        headerText: name,
        id: id,
      },
    });
  };

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }
  // Customizable Area End
}
