import React from "react";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/styles";

type Btn = {
    title: string;
    onClick?: any
}
type Props = {
    title: string;
    children: React.ReactChild | React.ReactChildren;
    className?: string;
    btn?: Btn
}

const CardStyle = styled(Box)({
    background: "#221835",
    boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.8)",
    borderRadius: "8px",
    padding: "24px",
    "& .cardHeader": {
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        "&.between": {
            justifyContent: "space-between"
        },
        "& .reportingBtn": {
            border: "none",
            outline: "none",
            background: "#38007C",
            borderRadius: "14px",
            padding: "9px 16px",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "10px",
            letterSpacing: "0.05em",
            color: "#FFFFFF"
        }
    },
    "& h4": {
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#FFFFFF",
        margin: "0"
    },
    "& .number": {
        fontWeight: "700",
        fontSize: "57px",
        lineHeight: "64px",
        color: "#FC6D52",
        display: "inline-block"
    }
});

const Card = ({ title, children, className, btn }: Props) => {
    return (
        <CardStyle className="card">
            <div className={`cardHeader ${className || ''}`}>
                <h4>{title}</h4>
                {btn && <button className="reportingBtn" onClick={btn?.onClick}>{btn.title}</button>}
            </div>
            <div className="cardBody">
                {children}
            </div>
        </CardStyle>
    )
}

export default Card