// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import { BrowserRouter as Router , Route, Switch } from "react-router-dom";
import {ProtectedRoute, PrivateRoute} from "./ProtectedRoute";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import "./App.css";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Customform from "../../blocks/customform/src/Customform";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Leaderboard2 from "../../blocks/leaderboard2/src/Leaderboard2";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Questionbank from "../../blocks/questionbank/src/Questionbank";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Cfimpactdata from "../../blocks/cfimpactdata/src/Cfimpactdata";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Engagement from "../../blocks/dashboard/src/Engagement-Dashboard.web";
import Impact from "../../blocks/dashboard/src/Impact-Dashboard.web";
import Reporting from "../../blocks/dashboard/src/Reporting-Dashboard.web";
import ScopeReporting from "../../blocks/dashboard/src/Scope-3-Reporting-Dashboard.web";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Walkthrough from "../../blocks/email-account-registration/src/Walkthrough.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import Loyaltysystem from "../../blocks/loyaltysystem/src/Loyaltysystem";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Fileattachment from "../../blocks/fileattachment/src/Fileattachment";
import Cfintegration4 from "../../blocks/cfintegration4/src/Cfintegration4";
import Category from "../../blocks/categoriessubcategories/src/Category.web"
import SubCategories from "../../blocks/categoriessubcategories/src/SubCategories.web"
import Navbar from '../../components/src/Navbar.web'
import BreadCrumb from '../../components/src/BreadCrumb.web'
import Details from '../../blocks/categoriessubcategories/src/Details.web'
import Rewards from '../../blocks/loyaltysystem/src/Rewards.web'
import RewardsSub from '../../blocks/loyaltysystem/src/RewardsSub.web';
import DashboardPage from '../../blocks/dashboard/src/DashboardPage.web';
import TermsAndCondition from "../../blocks/termsconditions/src/TermsAndCondition.web";
import AboutPage from '../../blocks/dashboard/src/AboutPage.web';

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View
        style={{
          height: '100vh',
          width: '100%',
          backgroundColor: '#1B122E',
          overflowX: 'hidden'
        }}
      >
        {/* <TopNav /> */}
        <Router>
        <Switch>
          <Route exact path="/EmailAccountLoginBlock" component={EmailAccountLogin} />
          <Route path="/EmailAccountRegistration" component={EmailAccountRegistration} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/ForgotPasswordOTP" component={ForgotPasswordOTP} />
          <Route path="/NewPassword" component={NewPassword} />
          <Route exact path="/TermsAndCondition" component={TermsAndCondition} />
          <Route exact path="/" component={TermsConditions} />
          <PrivateRoute path="/Walkthrough" component={Walkthrough} />
          <ProtectedRoute path="/NavigationMenu" component={NavigationMenu} />
          <ProtectedRoute path="/Customform" component={Customform} />
          <ProtectedRoute path="/EducationalUserProfile" component={EducationalUserProfile} />
          <ProtectedRoute path="/Emailnotifications2" component={Emailnotifications2} />
          <ProtectedRoute path="/OTPInputAuth" component={OTPInputAuth} />
          <ProtectedRoute path="/Contentmanagement3" component={Contentmanagement3} />
          <ProtectedRoute path="/VisualAnalytics" component={VisualAnalytics} />
          <ProtectedRoute path="/Leaderboard2" component={Leaderboard2} />
          <ProtectedRoute path="/Adminconsole2" component={Adminconsole2} />
          <ProtectedRoute path="/Questionbank" component={Questionbank} />
          <ProtectedRoute path="/ApiIntegration" component={ApiIntegration} />
          <ProtectedRoute path="/CountryCodeSelector" component={CountryCodeSelector} />
          <ProtectedRoute path="/UserProfileBasicBlock" component={UserProfileBasicBlock} />
          <ProtectedRoute path="/AccountGroups" component={AccountGroups} />
          <ProtectedRoute path="/Contactus" component={Contactus} />
          <ProtectedRoute path="/AddContactus" component={AddContactus} />
          <ProtectedRoute path="/Settings2" component={Settings2} />
          <ProtectedRoute path="/Rolesandpermissions" component={Rolesandpermissions} />
          <ProtectedRoute path="/Cfimpactdata" component={Cfimpactdata} />
          <ProtectedRoute path="/SocialMediaAccountLoginScreen" component={SocialMediaAccountLoginScreen} />
          <ProtectedRoute path="/SocialMediaAccountRegistrationScreen" component={SocialMediaAccountRegistrationScreen} />
          <ProtectedRoute path="/Dashboard" component={Dashboard} />
          <ProtectedRoute path="/ImportExportData" component={ImportExportData} />
          <ProtectedRoute path="/TermsConditions" component={TermsConditions} />
          <ProtectedRoute path="/TermsConditionsDetail" component={TermsConditionsDetail} />
          <ProtectedRoute path="/TermsConditionsUsers" component={TermsConditionsUsers} />
          <ProtectedRoute path="/BulkUploading" component={BulkUploading} />
          <ProtectedRoute path="/LandingPage" component={LandingPage} />
          <ProtectedRoute path="/Analytics" component={Analytics} />
          <ProtectedRoute path="/Loyaltysystem" component={Loyaltysystem} />
          <ProtectedRoute path="/Categoriessubcategories" component={Categoriessubcategories} />
          <ProtectedRoute path="/Fileattachment" component={Fileattachment} />
          <ProtectedRoute path="/Cfintegration4" component={Cfintegration4} />
          <ProtectedRoute exact path="/Categories" component={Category} />
          <ProtectedRoute path="/SubCategories" component={SubCategories} />
          <ProtectedRoute path="/Navbar" component={Navbar} />
          <ProtectedRoute path="/BreadCrumb" component={BreadCrumb} />
          <ProtectedRoute path="/Engagement" component={Engagement} />
          <ProtectedRoute path="/Impact" component={Impact} />
          <ProtectedRoute path="/Reporting" component={Reporting} />
          <ProtectedRoute path="/ScopeReporting" component={ScopeReporting} />
          <ProtectedRoute path="/InfoPage" component={InfoPage} />
          <ProtectedRoute path="/AlertWeb" component={AlertBlock} />
          <ProtectedRoute path="/Details" component={Details} />
          <ProtectedRoute path="/Rewards" component={Rewards} />
          <ProtectedRoute path="/RewardsStatus" component={RewardsSub} />
          <ProtectedRoute path="/EmployeeHome" component={DashboardPage} />
          <ProtectedRoute path="/AboutEzeo" component={AboutPage} />
        </Switch>
      </Router>
      </View>
    );
  }
}

export default App;
