import React from "react";

// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import CategoryController, {
  Props,
  configJSON
} from "./CategoryController.web";
import Navbar from "../../../components/src/Navbar.web";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { styled } from "@material-ui/styles";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from '@material-ui/core/CircularProgress';
import InfiniteScroll from "react-infinite-scroll-component";
// Customizable Area End

//Customizable Area Start
const CardBlock = styled(Box)({
  backgroundColor: "#1B122E",
  "& .headingText": {
    fontFamily: "Figtree, sans-serif",
    fontSize: "36px",
    color: "#FAFAFA",
    marginTop: "4%",
    marginLeft: "5%",
    width: "600px",
    fontWeight: 700,
    boxSizing: "border-box",
    "@media (max-width:600px)": {
      width: "auto",
      fontSize: "16px",
      marginTop: "5%",
      fontWeight: 400,
      marginBottom: "25px"
    }
  },
  "& .cardWrapper": {
    marginTop: "2%",
    "& .infinite-scroll-component__outerdiv": {
      width: "100%"
    }
  },
  "& .subCardwrapper": {
    justifyContent: "center",
    width: "94%",
    margin: "0px auto",
    "@media (max-width:600px)": {
      justifyContent: "unset"
    }
  },
  "& .cardContent": {
    backgroundColor: "rgba(250, 250, 250, 0.14)",
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: "14px 0px",
    "@media (max-width:600px)": {
      padding: "0px 0px"
    }
  },
  "& .imageContent": {
    marginTop: "2%",
    width: "190px",
    height: "100px",
    objectFit: "contain",
    "@media (max-width:600px)": {
      width: "100px"
    },
    "@media (max-width:320px)": {
      width: "100px"
    }
  },
  "& .textContent": {
    fontFamily: "Figtree, sans-serif",
    marginTop: "4%",
    height: "32px",
    color: "#FAFAFA",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: 700,
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700
    }
  },
  "& .subcardContent": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width:600px)": {
      paddingBottom: "0"
    }
  },
  "& .cardMain": {
    position: "relative",
    padding: "20px",
    "@media (max-width:600px)": {
      padding: "13px"
    }
  },
  "& .loader-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .loader": {
    height: "60vh"
  }
});

//Customizable Area End

export default class Category extends CategoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const CategoriesCard = (
      dataCard: {
        id: string;
        type: string;
        attributes: { id: number; image_url: string; name: string };
      }[],
      handleSubCategoryModal: (
        name: string,
        catName: string,
        id: string
      ) => void
    ) => {
      return (
        Array.isArray(dataCard) &&
        dataCard.length > 0 &&
        dataCard.map(
          (card: {
            id: string;
            type: string;
            attributes: { id: number; image_url: string; name: string };
          }) => {
            const ImgName = card.attributes.name;
            return (
              <Grid key={card.id} item sm={4} xs={6} className="cardMain">
                <Card
                  className="cardContent"
                  data-test-id="Category-Card-btn"
                  onClick={() =>
                    handleSubCategoryModal(
                      "SubCategories",
                      card.attributes.name,
                      card.id
                    )
                  }
                >
                  <CardContent className="subcardContent">
                    <img
                      alt={ImgName}
                      src={card.attributes.image_url}
                      className="imageContent"
                    />
                    <Typography className="textContent">{ImgName}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          }
        )
      );
    };
    const { dataCard, getCategoryError } = this.state;
    return (
      // Required for all blocks
      <Box bgcolor="#1B122E" height="100%">
        <Navbar
          // @ts-ignore
          params={"Categories"}
          headerText="Focus Area"
          history={this.props.history}
          contact_us={this.state.contact_us}
        />

        <Box>
          <CardBlock
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
            bgcolor="#1B122E"
            color="#FCFCFC"
          >
            <Hidden xsDown>
              <Typography className="headingText" variant="h4" component="h2">
                {configJSON.pick_your_focus_area}
              </Typography>
            </Hidden>
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <Typography className="headingText" variant="h4" component="h2">
                {configJSON.choose_the_areas}
              </Typography>
            </Hidden>
            <Grid container className="cardWrapper">
              <InfiniteScroll
                style={{ width: "100%" }}
                dataLength={dataCard.length}
                hasMore={this.state.hasData}
                next={this.handleCategoriesData}
                loader={<CircularProgress style={{ color: "#FC6D52" }} />}
              >
                {dataCard.length > 0 && (
                  <Grid container className="subCardwrapper" item>
                    {CategoriesCard(dataCard, this.handleSubCategoryModal)}
                  </Grid>
                )}
                <Box className={dataCard.length === 0? "loader-box loader":"loader-box"}>
                  {(!getCategoryError && dataCard.length === 0) && (
                    <CircularProgress style={{ color: "#FC6D52" }} />
                  )}
                  {!!getCategoryError && dataCard.length === 0 && (
                    <Typography>No Data found</Typography>
                  )}
                </Box>
              </InfiniteScroll>
            </Grid>
          </CardBlock>
        </Box>
      </Box>
    );
  }
}
