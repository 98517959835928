import React from "react";

// Customizable Area Start
import { Box, Button, Typography, Divider } from "@material-ui/core";
import SubCategoriesController, {
  Props,
  configJSON
} from "./SubCategoriesController.web";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { styled } from "@material-ui/styles";
import Navbar from "../../../components/src/Navbar.web";
import BreadCrumb from "../../../components/src/BreadCrumb.web";
import { box, vector } from "./assets";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Customizable Area End
const CardBlock = styled(Box)({
  "& .cardcontent": {
    marginTop: "1%",
    marginLeft: "5%",
    width: "90%",
    height: "auto%",
    backgroundColor: "#261E38",
    borderRadius: "24px",
    marginBottom: "1%"
  },
  "& .routtext": {
    marginTop: "2%",
    color: "#FAFAFA",
    marginLeft: "5%"
  },
  "& .cardtitle": {
    fontFamily: "Figtree, sans-serif",
    marginTop: "3%",
    color: "#FAFAFA",
    fontSize: "16px",
    fontWeight: 700,
    height: "24px",
    marginLeft: "5%",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "10px"
    }
  },
  "& .selectcontent": {
    width: "180px",
    height: "40px",
    borderColor: "#FAFAFA",
    backgroundColor: "#1B122E",
    borderRadius: "50px"
  },
  "& .optioncontent": {
    backgroundColor: "red"
  },
  "& .imagecontent": {
    weight: "10px",
    height: "10px",
    backgroundColor: "#3A324B"
  },
  "& .cardfirstdata": {
    fontFamily: "Figtree, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    height: "20px",
    color: "rgba(250, 250, 250, 0.6)",
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .cardfirstdata-mobile": {
    fontFamily: "Figtree, sans-serif",
    fontSize: "12px",
    fontWeight: 500,
    height: "20px",
    color: "rgba(250, 250, 250, 0.6)",
    display: "none",
    "@media (max-width:600px)": {
      display: "inline-block"
    }
  },
  "& .cardseconddata": {
    fontFamily: "Figtree, sans-serif",
    marginTop: "6px",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "14px"
    }
  },
  "& .buttoncontent": {
    fontFamily: "Figtree, sans-serif",
    textTransform: "unset",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "25px",
    padding: "5px 45px",
    height: "40px",
    border: "2px solid #FAFAFAb0",
    color: "#FAFAFA",
    position: "unset",
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .dividercontent": {
    backgroundColor: "rgba(250, 250, 250, 0.12)",
    marginTop: "5px",
    marginBottom: "5px"
  },
  "& .imageContent": {
    weight: "40px",
    height: "40px"
  },
  "& .breadcrumcontent": {
    weight: "40px",
    height: "40px",
    color: "#FAFAFA",
    fontSize: "14px",
    fontWeight: 500,
    textDecoration: "none"
  },
  "& .after": {
    boxSizing: "border-box"
  },
  "& .before": {
    boxSizing: "border-box"
  },
  "& .dropdown-wrapper": {
    cursor:"pointer",
    fontFamily: "Figtree, sans-serif",
    position: "relative",
    width: "210px",
    margin: "10px",
    padding: "12px 15px",
    backgroundColor: "#1B122E",
    border: "1px solid #FAFAFA",
    borderRadius: "25px",
    boxShadow: "0 1px 0 rgba(0, 0, 0, 0.2)",
    "@media (max-width:600px)": {
      width: "auto",
      padding: "8px 15px"
    }
  },
  "& .dropdown-wrapper:after": {
    content: "",
    width: "0",
    height: "0",
    position: "absolute",
    top: "50%",
    marginTop: "-3px",
    borderWidth: "6px 6px 0 6px",
    borderStyle: "solid",
    borderColor: "#f05b55 transparent",

    marginLeft: "10%"
  },
  "& .dropdown-wrapper.is-active": {
    borderRadius: "5px 5px 0 0",
    backgroundColor: "#3A324B"
  },
  "& .dropdown-wrapper.is-active:after": {
    borderColor: "#ffffff transparent",
    transform: "rotate(180deg)"
  },
  "& .dropdown-list": {
    fontFamily: "Figtree, sans-serif",
    position: "absolute",
    maxHeight: "400px",
    top: "50px",
    right: "0",
    backgroundColor: "#3A324B",
    borderRadius: "20px",
    borderColor: "1px solid rgba(0, 0, 0, 0.2)",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    width: "240px",
    height: "112px",
    zIndex: 100,
    padding: "5%",
    cursor: "pointer"
  },
  "& .dropdown-list li": {
    padding: "0 10px"
  },
  "& .dropdown-list li:last-of-type a": {
    border: "none"
  },
  "& .dropdown-list a": {
    marginTop: "10px",
    display: "block",
    textDecoration: "none",
    fontSize: "16px",
    color: "#FAFAFA",
    padding: " 10px 0",
    transition: "all 0.3s ease-out",
    zIndex: 100
  },
  "& .dropdown": {
    width: "14px",
    fontSize: "16px",
    marginTop: "2px",
    marginLeft: "5px",
    marginRight: "7px",
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .dropdown-after": {
    width: "14px",
    fontSize: "16px",
    marginTop: "2px",
    marginLeft: "5px",
    display: "none",
    "@media (max-width:600px)": {
      display: "inline-block"
    }
  },
  "& .bread-crumb-box": {
    "@media (max-width:600px)": {
      justifyContent: "end"
    }
  },
  "& .dropdown-title": {
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .sort-by-title": {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    "@media (max-width:600px)": {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "24px"
    }
  }
});

const DrawerWrapper = styled(Drawer)({
  "& .Mui-checked": {
    color: "yellow"
  },
  "& .MuiRadio-root": {
    color: "yellow"
  },
  "& .MuiFormControlLabel-root": {
    "& .MuiTypography-body1": {
      color: "#fff"
    }
  },
  "& .MuiDrawer-paperAnchorBottom": {
    borderRadius: "25px 25px 0 0",
    padding: "20px 30px",
    backgroundColor: "#3a324b"
  },
  "& .drawerTitle": {
    fontFamily: "Figtree, sans-serif",
    color: "#f06951"
  }
});

export default class SubCategories extends SubCategoriesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      showDropDown,
      selectData,
      breadCrumData,
      dropdownData,
      dataCard
    } = this.state;

    return (
      <Box bgcolor="#1B122E" height="100%">
        <Navbar
          // @ts-ignore
          history={this.props.history}
          params={"Categories"}
          headerText={this.props.history.location.state.headerText}
          contact_us={this.state.contact_us}
        />
        <Box>
          <CardBlock
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
            bgcolor="#1B122E"
            color="#FCFCFC"
          >
            <Box
              className="bread-crumb-box"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "90%",
                marginLeft: "5%",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: { xs: "22px", md: "27px" }
              }}
            >
              <BreadCrumb
                params={""}
                history={this.props.history}
                breadcrumData={breadCrumData}
              />

              <Box className="sort-category-dropdwon">
                <div
                  id="dropdown-wrapper"
                  data-test-id="sort-btn"
                  className="dropdown-wrapper"
                  onClick={this.handleOpenDropDown}
                >
                  <span className="sort-by-title">
                    <img src={vector} className="dropdown" />{" "}
                    <span className={selectData ? "dropdown-title" : ""}>
                      {configJSON.sort_by}
                    </span>{" "}
                    {selectData}
                    <img src={vector} className="dropdown-after" />
                  </span>
                  <Hidden only={["xs"]}>
                    {showDropDown && (
                      <>
                        <ul className="dropdown-list">
                          {dropdownData.length > 0 &&
                            dropdownData.map((data: {label:string; value:number;}) => {
                              return (
                                <li
                                  key={data.label}
                                  id="dropdowndata"
                                  data-test-id="dropdowndata"
                                  onClick={() => this.handleChange(data.label)}
                                >
                                  <a>{data.label}</a>
                                </li>
                              );
                            })}
                        </ul>
                      </>
                    )}
                  </Hidden>
                  <Hidden only={["sm", "md", "lg", "xl"]}>
                    {showDropDown && (
                      <DrawerWrapper anchor={"bottom"} open={true}>
                        <Typography variant="h6" className="drawerTitle">
                          {configJSON.sort_by}
                        </Typography>
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={this.state.selectData}
                        >
                          {dropdownData.length > 0 &&
                            dropdownData.map((data: {label:string;value: number;}) => {
                              return (
                                <FormControlLabel
                                  key={data.label}
                                  id="dropdown-data"
                                  value={data.label}
                                  control={<Radio />}
                                  label={data.label}
                                  onClick={() => this.handleChange(data.label)}
                                />
                              );
                            })}
                        </RadioGroup>
                      </DrawerWrapper>
                    )}
                  </Hidden>
                </div>
              </Box>
            </Box>
            {dataCard && dataCard.length > 0 &&
              dataCard.map((card: {id:string; subCategory:string; solutions:({ id: string; } | undefined)[]}) => {
                return (
                  <Box key={card.subCategory}>
                    <Typography className="cardtitle">
                      {card.subCategory}
                    </Typography>
                    <Card className="cardcontent">
                      <CardContent>
                        {card.solutions && card.solutions.length > 0 ? (
                          card.solutions.map(
                            (data: any, index: number, array: any) => {
                              return (
                                <div key={index + 0}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "8px",
                                      justifyContent: "space-between"
                                    }}
                                    data-test-id="details-div"
                                    onClick={(
                                      e: React.MouseEvent<
                                        HTMLDivElement,
                                        MouseEvent
                                      >
                                    ) =>
                                      this.handleClick(
                                        e,
                                        "clickOnDiv",
                                        card.subCategory,
                                        data
                                      )
                                    }
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                    >
                                      <img src={box} className="imageContent" />
                                      <Box sx={{ marginLeft: "15px" }}>
                                        <Typography
                                          className="cardfirstdata"
                                          component="div"
                                        >
                                          Ezeo Rewards :
                                          {data.attributes.ezeo_reward_points}{" "}
                                          points
                                        </Typography>
                                        <Typography
                                          className="cardseconddata"
                                          component="div"
                                        >
                                          {data.attributes.solution_title}
                                        </Typography>
                                        <Typography
                                          className="cardfirstdata-mobile"
                                          component="div"
                                        >
                                          Ezeo Rewards :
                                          {data.attributes.ezeo_reward_points}{" "}
                                          points
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Button
                                        className="buttoncontent"
                                        variant="outlined"
                                        data-trenderSettingModelrenderSettingModelest-id="details-btn"
                                        data-test-id="view-details-btn"
                                        onClick={(
                                          e: React.MouseEvent<
                                            HTMLButtonElement,
                                            MouseEvent
                                          >
                                        ) =>
                                          this.handleClick(
                                            e,
                                            "clickOnButton",
                                            card.subCategory,
                                            data
                                          )
                                        }
                                      >
                                        {configJSON.view_details}
                                      </Button>
                                    </Box>
                                  </Box>
                                  {array.length - 1 !== index && (
                                    <Divider
                                      className="dividercontent"
                                      variant="middle"
                                    />
                                  )}
                                </div>
                              );
                            }
                          )
                        ) : (
                          <Typography className="cardseconddata">
                            No data found
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                );
              })}
          </CardBlock>
        </Box>
      </Box>
    );
  }
}
