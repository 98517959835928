import { Checkbox, FormControlLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import React from "react";

const ExportContentStyle = styled('div')({
    paddingTop: '24px',
    maxWidth: '552px',
    width: '552px',
    "& *": {
        boxSizing: "border-box"
    },
    '& h5': {
        color: '#FAFAFA',
        margin: '0',
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '24px',
    },
    '& p': {
        color: '#FAFAFA',
        margin: '0',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        marginTop: '4px'
    },
    '& .checkbox': {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        '& .MuiFormControlLabel-root': {
            marginLeft: '0',
            gap: '8px',
            '& .MuiButtonBase-root': {
                padding: '0',
                color: '#ECEF51'
            },
            '& .MuiTypography-root': {
                color: '#FAFAFA'
            }
        }
    },
    '& .actionBtn': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '24px',
        '& .exportBtn': {
            padding: '12px 32px',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '24px',
            borderRadius: '24px',
            background: '#ECEF51',
            border: 'none',
            boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 13px 50px 0px rgba(0, 0, 0, 0.09)',
        },
        '& .cancelBtn': {
            padding: '12px 32px',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '24px',
            borderRadius: '24px',
            background: 'transparent',
            border: '1.5px solid #FAFAFA',
            color: '#FAFAFA',
            opacity: '0.6000000238418579'
        }
    }
})

const ExportContent = ({ handleClose }: any) => {
    const [checkedPDF, setCheckedPDF] = React.useState(false);
    const [checkedCSV, setCheckedCSV] = React.useState(false);

    const handleChange = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (type === "PDF") {
            setCheckedPDF(event.target.checked);
        } else {
            setCheckedCSV(event.target.checked);
        }
    };
    return (
        <ExportContentStyle>
            <h5>Export</h5>
            <p>Which format would you like to export in ?</p>
            <div className="checkbox">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkedPDF}
                            onChange={handleChange('PDF')}
                            value="PDF"
                        />
                    }
                    label="Scope 3 Summary Report (PDF)"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkedCSV}
                            onChange={handleChange("CSV")}
                            value="CSV"
                        />
                    }
                    label="Scope 3 Details Report (CSV)"
                />
            </div>
            <div className="actionBtn">
                <button onClick={() => handleClose()} className="cancelBtn">Cancel</button>
                <button onClick={() => handleClose()} className="exportBtn">Export</button>
            </div>
        </ExportContentStyle>
    )
}


export default ExportContent;