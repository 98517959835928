import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { styled } from "@material-ui/styles";

type MyProps = {
    label: string,
    value: number,
    amount: string
}

const ProgressBarStyle = styled(Box)({
    marginBottom: '16px',
    '& label': {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },
    '& .progressMain': {
        marginTop: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiLinearProgress-root': {
            width: 'calc(100% - 100px)',
            height: '16px',
            backgroundColor: 'transparent',
            borderRadius: '8px',
            '& .MuiLinearProgress-bar': {
                background: '#B8AAD0',
                opacity: '0.8',
                borderRadius: '8px'
            }
        },
        '& span': {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#FFFFFF',
        }
    }
})
export default class ProgressBar extends React.Component<MyProps> {
    render() {
        const { label, value, amount } = this.props
        const progress = (value/1000)*100;
        return (
            <ProgressBarStyle>
                <label>{label}</label>
                <div className="progressMain">
                    <LinearProgress variant="determinate" value={progress}/>
                    <span>{amount}</span>
                </div>
            </ProgressBarStyle>
        )
    }
}