import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any; 
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  emailError: string;
  errorE: boolean;
  passwordError: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  openSnackbar: boolean;
  errorMsg: string;
  logout: boolean;
  errorPassword: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallWebId: string = "";
  validationApiCallWebId: string = "";
  emailReg: RegExp;
  passwordReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      emailError: "",
      errorE: false,
      password: "",
      passwordError: "",
      enablePasswordField: true,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      openSnackbar: false,
      errorMsg: '',
      logout: false,
      errorPassword: '',
    };

    this.emailReg = new RegExp(configJSON.emailRegs);
    this.labelTitle = configJSON.labelTitle;
    this.passwordReg = new RegExp(configJSON.passwordRegs);
    this.handleCloseSnackBar = this.handleCloseSnackBar.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.backNavigation();
    // Customizable Area End
  }

  // Customizable Area Start

  backNavigation = () => {
    const token = localStorage.getItem("user_token");
    const logout = localStorage.getItem("Logout");
    if (token !== null && logout === 'false') {
      this.props.history.push("/Categories")
    }
  }

  loginAccountResponse = (from: string, message: Message) => {
    if (this.apiEmailLoginCallWebId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
      );

      if (apiResponse && 'errors' in apiResponse) {
          this.setState({ openSnackbar: true, errorMsg: apiResponse.errors[0].failed_login, email: "" , password: "", errorPassword: apiResponse.errors[0].password_error });
      } 
      // else if(){

      // }
      else {
        if (apiResponse.meta.token !== "")
         {
          localStorage.setItem("user_token", apiResponse.meta.token);
            localStorage.setItem("Logout", 'false');
            if(apiResponse.meta.user_role=="employer"){
                this.props.history.push("/Engagement");}
            else{ this.props.history.push("/EmployeeHome");}
        }
      }
    }
  }

  handlePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ password: event.target.value  });
    if (event.target.value  === '') {
      this.setState({ passwordError: configJSON.errorPasswordNotValid });
    }else{
      this.setState({ passwordError: '' });
    }
  };

  handleEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ email: event.target.value })
    if (event.target.value === '') {
      this.setState({ emailError: configJSON.errorEmailRequired });
    } else if (!this.emailReg.test(event.target.value)) {
      this.setState({ emailError: configJSON.errorEmailNotValid });
    }
    else {
      this.setState({ emailError: "" });
      this.setState({ errorE: false });
    }
  }


  enablePasswordShow = () => {
    this.setState({enablePasswordField: !this.state.enablePasswordField});
  }

  handleSubmit(): boolean {
    if (
      this.state.email === "") {
      this.setState({ emailError: configJSON.errorEmailRequired , errorE: true});
      return false;
    } else if (!this.emailReg.test(this.state.email)) {
        this.setState({ emailError: configJSON.errorEmailNotValid });
        this.setState({ errorE: true });
        return false;
    }

    if (this.state.password === null || this.state.password == '') {
      this.setState({ passwordError: configJSON.errorPasswordNotValid });
      return false;
    }

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallWebId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signInEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCloseSnackBar() {
    this.setState({ openSnackbar: false, errorMsg: '' });
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.loginAccountResponse(from, message);
    // Customizable Area End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallWebId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
