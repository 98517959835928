import React from "react";
import { Grid } from "@material-ui/core";
import Sidebar from "../../../components/src/sidebar.web";
import BasicTable from "../../../components/src/BasicTable.web";
import ProgressBar from "../../../components/src/ProgressBar.web";
import Card from "../../../components/src/Card.web";
import { DashboardStyle } from "./Engagement-Dashboard.web";
import DashboardPageEmployerController from "./DashBordPageEmployerController.web";

const rows = [
  {
    title: "Scope 3 directly attributable",
    co2: "10 000",
    CarbonOffsetCosts: "10 000",
    IncentiveOffering: "8 000",
    NetSavings: "12 000"
  },
  {
    title: "Scope 3 indirectly attributable",
    co2: "14 000",
    CarbonOffsetCosts: "8 000",
    IncentiveOffering: "6 000",
    NetSavings: "20 000"
  },
  {
    title: "Socially Attributable",
    co2: "14 000",
    CarbonOffsetCosts: "8 000",
    IncentiveOffering: "6 000",
    NetSavings: "20 000"
  },
  {
    title: "Total",
    co2: "30 000",
    CarbonOffsetCosts: "26 000",
    IncentiveOffering: "20 000",
    NetSavings: "52 000"
  }
];
export default class Impact extends DashboardPageEmployerController {
  // constructor(){
  //     su
  // }
  render() {
    console.log("isue is found", this.state.impectData)
    return (
      <DashboardStyle>
        <Sidebar active={2} history={this.props.history} handleChange={this.handleChange} employerData={this.state.employerData} handleChangeEmployerData={this.handleChangeEmployerData} handleUpdateEmployer={this.handleUpdateEmployer} errorMessage={this.state.errorMessage} fieldRequiredData={this.state.fieldRequiredData} handleClearData={this.handleClearData} switchData={this.state.switchData} errorMsgPasValidation={this.state.errorMsgPasValidation} handleEmployerData={this.handleEmployerData} contactUsData={this.state.contactUsData}/>
        <main>
          <div className="heading">
            <h2 data-test-id="heading">Impact</h2>
          </div>
          <div className="body">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card title="Carbon Emission overview">
                  <BasicTable
                    align="center"
                    columns={[
                      "Title",
                      "CO2 (t)",
                      "Carbon Offset Costs ($)",
                      "Incentive offering ($)",
                      "Net savings ($)"
                    ]}
                    rows={this.state.impectData}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card title="Emissions Reductions by Category">
                  <>
                  {this.state.categotyCount.map((data:any) => {
                    return(
                      <ProgressBar key={data.id}
                      label={data.name}
                      value={data.count}
                      amount={data.count}
                    />
                    )
                  })}
                    {/* <ProgressBar
                      label="Home (t)"
                      value={86}
                      amount={"16 000"}
                    />
                    <ProgressBar
                      label="Investment (t)"
                      value={43}
                      amount={"8 000"}
                    />
                    <ProgressBar label="Tech (t)" value={38} amount={"6 000"} />
                    <ProgressBar
                      label="Energy (t)"
                      value={76}
                      amount={"12 000"}
                    />
                    <ProgressBar
                      label="Transport (t)"
                      value={86}
                      amount={"16 000"}
                    /> */}
                  </>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card title="Average Individual Employee Impact">
                  <>
                  {this.state.avgEmpImpact.map((key:any, value:any) => {
                    return(
                      <ProgressBar key={key}
                      label={key[0]}
                      value={key[1]}
                      amount={key[1] == null ? 0 : key[1]}
                    />
                    )
                  })}
                    {/* <ProgressBar
                      label="Total Scope 3 directly attributable CO2"
                      value={86}
                      amount={"400"}
                    />
                    <ProgressBar
                      label="Total Scope 3 indirectly attributable CO2"
                      value={43}
                      amount={"200"}
                    />
                    <ProgressBar
                      label="Total Socially attributable CO2"
                      value={38}
                      amount={"150"}
                    /> */}
                  </>
                </Card>
              </Grid>
            </Grid>
          </div>
        </main>
      </DashboardStyle>
    );
  }
}
