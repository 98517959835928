import React from "react"
import { styled } from "@material-ui/styles";
type MyState = {  switchData: boolean };
type MyProps = {handleSwitchChange?: () => void; switchData: boolean};

const SwitchStyle = styled('label')({
    position: 'relative',
    display: 'inline-block',
    width: '48px',
    height: '28px',
    '& input': {
        opacity: 0,
        width: 0,
        height: 0,
        '&:checked': {
            '& + .slider': {
                backgroundColor: '#D1BCE3',
                '& .pointer': {
                    '-webkit-transform': 'translateX(20px)',
                    '-ms-transform': 'translateX(20px)',
                    transform: 'translateX(20px)',
                }
            }
        }
    },
    '& .slider': {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#ccc',
        '-webkit-transition': '.4s',
        transition: '.4s',
        borderRadius: '34px',
        '& .pointer': {
            position: 'absolute',
            content: "",
            height: '24px',
            width: '24px',
            left: '2px',
            bottom: '2px',
            backgroundColor: 'white',
            borderRadius: '50%',
            '-webkit-transition': '.4s',
            transition: '.4s',
        }
    }
})

export default class Switch extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = { 
          switchData: false,
        };
      }
    handleChange = () => {
        if (this.props.handleSwitchChange) {
            this.props.handleSwitchChange();
        }
    }
    render() {
        return (
            <SwitchStyle data-test-id="switch-data" onChange={this.handleChange}>
                <input type="checkbox" checked={this.props.switchData}/>
                <span className="slider round"><span className="pointer"></span></span>
            </SwitchStyle>
        )
    }
}