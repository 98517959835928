export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const homelogo = require("../assets/home.png");
export const coin = require("../assets/coin.png");
export const tech = require("../assets/tech.png");
export const energy = require("../assets/energy.png");
export const travel = require("../assets/travel.png");
export const icon = require("../assets/icon.png");
export const vector = require("../assets/vector.png");
export const cubeImage = require("../assets/cubeImg.png");
export const star = require("../assets/star.png");
export const UploadPin = require("../assets/uploadPin.png");
export const CloseIcon = require("../assets/close_icon.png");
export const sucesslogo = require("../assets/sucess_logo.png");
export const formBackground = require("../assets/form_background.png");
export const box = require("../assets/box.png");
