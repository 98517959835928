import React from "react";
import { styled } from "@material-ui/styles";
import { Dialog, DialogTitle, DialogContent, Slide } from "@material-ui/core";
type MyProps = { open: boolean, handleClose: any, title?: string }

const DialogBlock = styled(Dialog)({
    '& .MuiPaper-root': {
        background: '#221835',
        boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.8)',
        borderRadius: '8px',
        maxHeight:'100% !important',
        '& .MuiDialogTitle-root': {
            padding: '24px',
            '& .MuiTypography-root': {
                fontFamily: "'Figtree', sans-serif",
                fontWeight: '700',
                fontSize: '32px',
                lineHeight: '40px',
                color: '#FAFAFA',
            }
        },
        '& .MuiDialogContent-root': {
            padding: '0 24px 24px'
        },
        
    }
})
export default class DialogModel extends React.Component<MyProps> {
    componentDidMount(): void {
        const ele: any = document.getElementById('root')
        ele.style.filter = this.props.open ? "blur(2px)" : ''
    }
    componentWillUnmount() {
        const ele: any = document.getElementById('root')
        ele.style.filter = ''
    }
    render() {
        return (
            <DialogBlock
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                hideBackdrop={true}
            >
                {this.props.title && <DialogTitle id="alert-dialog-slide-title">
                    {this.props.title}
                </DialogTitle>}
                <DialogContent>
                    {this.props.children}
                </DialogContent>
            </DialogBlock>
        )
    }
}


function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}