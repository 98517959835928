import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataCard: {id:string, description:string, name:string, title:string };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TermsAndConditionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTermsAndCondition: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      dataCard: {id:"", description:"", name:"", title:""}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getTermsAndConditionApi()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.callAfterApiCall(apiRequestCallId, responseJson);
    }
    // Customizable Area Start
    // Customizable Area End
  }

  callAfterApiCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getTermsAndCondition) {
      if (!responseJson.error) {
      const terms = responseJson.filter((data: any) => {
        return data.title === "Terms and Conditions"        
      })
        this.setState({ dataCard: terms[0]})       
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    } 
  };

  // Customizable Area Start

  getTermsAndConditionApi = () => {
    this.getTermsAndCondition = sendAPIRequest(configJSON.termsAndCondition);
  };

  handleNavigateSignup = () => {
    this.props.history.push({pathname: "EmailAccountRegistration"});
  }

  // Customizable Area End
}
