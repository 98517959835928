import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { styled } from "@material-ui/styles";

type MyProps = { 
    columns: string[], 
    rows: any[], 
    align: "left" | "right" | "inherit" | "center" | "justify" | undefined 
}

const TableStyle = styled(Table)({
    '& .MuiTableCell-root': {
        color: '#FFFFFF',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        borderColor: 'rgba(166, 151, 187, 0.3)',
        fontFamily:  "'Figtree', sans-serif",
        '&.MuiTableCell-head': {
            color: '#A697BB',
            fontWeight: '600',
        }
    },
    '& .total':{
        '& .MuiTableCell-root': {
            padding: '30px 0',
            fontWeight: '600',
            fontSize: '16px',
            border: 'none'
        }
    }
})
export default class BasicTable extends React.Component<MyProps> {
    render() {
        const { columns,  align } = this.props
        const { rows } = this.props

        return (
            <TableStyle>
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell align={align}>{column}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row,index) => (
                        <TableRow key={index} className={row?.title === "Total" ? 'total' : ''}>
                            {Object.keys(row).map(key => <TableCell align={align}>{row[key]}</TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </TableStyle>
        )
    }
}