// Customizable Area Start
import React from "react";
import {
    Grid,
    Box,
    Button,
    Input,
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { designImage, logo, mobileBg } from "./assets";
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { InputEye } from "../../../components/src/InputEye";

const DesignGrid = styled(Grid)({
    '@media(max-width: 767px)': {
        display: 'none!important'
    },
});

const ParentGrid = styled(Grid)({
    background: '#1B122E',
    '@media(max-width: 425px)': {
        background: 'rgb(37, 24, 64)',
        height: "-webkit-fill-available",
    },
});

const LoginGrid = styled(Grid)({
    '@media(max-width: 425px)': {
        paddingLeft: '35px!important',
        background: `url(${mobileBg})`,
        backgroundSize: '200px',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        paddingTop: '170px !important',
        backgroundPositionY: '96px',
        paddingBottom: '10px',
        width: '100px',
        objectFit: 'cover'
    },
});

const AccountDiv = styled('div')({
    width: '100%',
    height: '24px',
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#FAFAFA',
    '@media(max-width: 767px)': {
        position: 'unset',
        fontSize: '14px',
        justifyContent: 'center',
    },
});

const StyledInput = styled(Input)({
    border: "1px solid #FAFAFA",
    borderRadius: '8px',
    width: '70%',
    height: '48px',
    padding: '0px 16px',
    background: "#1B122E",
    color: '#FAFAFA',
    fontSize: '16px',
    fontFamily: 'Figtree',
    "& .MuiInputBase-input:-webkit-autofill" : {
        '-webkitTextFillColor': '#fff',
        background: '#2a0440',
        '-webkitBoxShadow': '0 0 0px 1000px #2a0440 inset',
    },
    '@media (max-width: 425px)': {
        width: '100%',
        fontSize: "14px",
        lineHeight: "20px",
        background: "#24183f",
    }
});

const LogoImage = styled("img")({
    paddingBottom: '88px',
    width: '200px',
    height: '51px',
    fontFamily: 'Figtree',
    marginTop: '106px',
    "@media (max-width: 425px)": {
        width: "150px",
        height: "38px",
        objectFit: 'cover',
        paddingBottom: "40px",
    }
})

const LoginLabel = styled("label")({
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FAFAFA",
    "@media (max-width: 425px)": {
        fontSize: "12px",
        lineHeight: '16px'
    }
})

const LoginErrors = styled("span")({
    color: '#EA7D7D',
    marginBottom: '10px',
    lineHeight: "24px",
    display: 'inline-block',
    fontSize: '16px',
    "@media (max-width: 425px)": {
        fontSize: "12px",
        lineHeight: "16px",
    }
})

const LoginHeading = styled("p")({
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    color: "#FAFAFA",
    paddingBottom: "24px",
    margin: '0px',
    fontFamily: 'Figtree',
    "@media (max-width: 425px)": {
        fontSize: '24px',
    }
})

const NextButton = styled(Button)({
    background: "linear-gradient(0deg, rgba(250, 250, 250, 0.14), rgba(250, 250, 250, 0.14)), #1B122E",
    borderRadius: "24px 4px",
    width: '70%',
    height: '48px',
    color: "rgba(250, 250, 250, 0.4)",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: '34px',
    textTransform: 'capitalize',
    marginTop: '20px',
    '&:hover': {
        backgroundColor: "#ECEF51",
        color: '#000'
    },
    '@media (max-width: 425px)': {
        width: '100%',
        fontSize: '16px',
    }
})

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

import EmailAccountLoginController, { Props } from "./EmailAccountLoginController.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class EmailAccountLogin extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
    }

    changeMode() {
        if (this.state.enablePasswordField) {
            return "password";
        } else {
            return "text";
        }
    }

    render() {

        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <ParentGrid container spacing={3} >
                    <DesignGrid item style={webStyle.designDiv} md={6}>
                        {/* <BackgroundImg /> */}
                        <img style={webStyle.designImage} src={designImage} alt="" />
                    </DesignGrid>
                    <LoginGrid item style={webStyle.contentBox} md={6}>
                        <LogoImage src={logo} alt="" />
                        <LoginHeading>Log in</LoginHeading>
                        <Box sx={webStyle.inputBox}>
                            <LoginLabel>Work email ID</LoginLabel>
                                    <StyledInput
                                        autoComplete="off"
                                        placeholder="Enter work email address"
                                        data-test-id="txtInputEmail"
                                        value={this.state.email}
                                        onChange={this.handleEmail}
                                        style={{ border: this.state.emailError ? '1px solid #EA7D7D' : '1px solid #FAFAFA' }}
                                    />
                            <LoginErrors>{this.state.emailError}</LoginErrors>
                        </Box>
                        <Box sx={webStyle.inputBox}>
                            <LoginLabel>Password</LoginLabel>
                                    <StyledInput
                                        autoComplete="off"
                                        data-test-id="txtInputPassword"
                                        placeholder="Enter password"
                                        type={this.changeMode()}
                                        value={this.state.password}
                                        onChange={this.handlePassword}
                                        style={{ border: this.state.passwordError ? '1px solid #EA7D7D' : '1px solid #FAFAFA' }}
                                        endAdornment={<InputEye dataid="btnShowHidePassword" enablePasswordField={this.state.enablePasswordField} handleShowPassword={this.enablePasswordShow} />}
                                    />
                            <LoginErrors>{this.state.errorPassword}</LoginErrors>
                        </Box>
                        
                        <Box
                            data-test-id={"btnForgotPassword"}
                            onClick={() => this.goToForgotPassword()}
                            sx={webStyle.forgotpasswordDiv}>
                                <Link style={{ textDecoration: 'none', color: '#67ADFF' }} to='/ForgotPassword'>
                            Forgot Password ?
                            </Link>
                        </Box>
                       
                        <NextButton
                            data-test-id="btnUserLogin"
                            onClick={() => this.handleSubmit()}
                        >
                            Log in {/*UI Engine::From Sketch*/}
                        </NextButton>
                        <AccountDiv>
                            <p>Do not  have an account ?</p >
                            <Link to='/EmailAccountRegistration' style={{ color: '#67ADFF', paddingLeft: '10px', textDecoration: 'none', fontSize: "16px" }}>Signup</Link>
                        </AccountDiv>
                    </LoginGrid>
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }} open={this.state.openSnackbar} autoHideDuration={5000} onClose={this.handleCloseSnackBar}>
                        <Alert onClose={this.handleCloseSnackBar} severity="error">
                            {this.state.errorMsg}
                        </Alert>
                    </Snackbar>
                </ParentGrid>
            </ThemeProvider>
        );
    }
}

const webStyle = {

    contentBox: {
        paddingLeft: '130px',
        width: '100%',
        paddingRight: '33px',
        '@media(max-width: 425px)': {
            paddingRight: '55px',
        }
    },
    designImage: {
        width: '400px',
        height: '800px',
    },
    designDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: "linear-gradient(0deg, rgba(250, 250, 250, 0.05), rgba(250, 250, 250, 0.05)), #1B122E",
        borderRadius: "0px 0px 400px 0px",
        padding: '112px 0px',
        fontFamily: "Figtree"
    },
    inputBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        fontFamily: 'Figtree',
        "& .MuiInput-underline:after": {
            display: 'none !important',
        }
    },
    accountDiv: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FAFAFA",
        margin: '0px',
        fontFamily: 'Figtree',
    },
    forgotpasswordDiv: {
        color: '#67ADFF',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'end',
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        // position: 'absolute',
        width: '70%',
        height: '24px',
        marginBottom: '10px',
        '@media (max-width: 425px)': {
            width: '100%',
            fontSize: "14px"
        }
    }
};

  // Customizable Area End