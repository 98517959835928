import React from "react";
import {
  InputAdornment,
  IconButton
} from "@material-ui/core";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

interface Props {
  enablePasswordField: boolean,
  handleShowPassword: any,
  dataid: string
}
export const InputEye: React.FC<Props> = (props) => {

  const { enablePasswordField, handleShowPassword, dataid } = props;
  return (
    <InputAdornment position="end">
      <IconButton
        data-test-id={dataid}
        style={webStyle.iconDiv}
        aria-label="toggle password visibility"
        onClick={handleShowPassword}
        edge="end"
      >
        {enablePasswordField ? (
          <VisibilityOffOutlinedIcon />
        ) : (
          <VisibilityOutlinedIcon />
        )}
      </IconButton>
    </InputAdornment>
  )
}

const webStyle = {
  iconDiv: {
    color: "white"
  }
};