import React from "react";
import { Grid } from "@material-ui/core";
import Sidebar from "../../../components/src/sidebar.web";
import DataTable from "../../../components/src/DataTable.web";
import Card from "../../../components/src/Card.web";
import { DashboardStyle } from "./Engagement-Dashboard.web";
import  DashboardPageEmployerController  from "./DashBordPageEmployerController.web";

const rows = [
  {
    category: "Home appliances0",
    employeesNumber: 50,
    CarbonImpact: 520
  },
  {
    category: "My Home appliances1",
    employeesNumber: 51,
    CarbonImpact: 522
  },
  {
    category: "Home appliances2",
    employeesNumber: 52,
    CarbonImpact: 524
  },
  {
    category: "Home appliances3",
    employeesNumber: 53,
    CarbonImpact: 526
  },
  {
    category: "Home appliances4",
    employeesNumber: 54,
    CarbonImpact: 524
  },
  {
    category: "Home appliances5",
    employeesNumber: 55,
    CarbonImpact: 525
  },
  {
    category: "Home appliances6",
    employeesNumber: 56,
    CarbonImpact: 526
  },
  {
    category: "Home appliances7",
    employeesNumber: 57,
    CarbonImpact: 527
  },
  {
    category: "Home appliances8",
    employeesNumber: 58,
    CarbonImpact: 528
  },
  {
    category: "Home appliances9",
    employeesNumber: 59,
    CarbonImpact: 529
  },
  {
    category: "Home appliances10",
    employeesNumber: 60,
    CarbonImpact: 530
  },
  {
    category: "Home appliances11",
    employeesNumber: 61,
    CarbonImpact: 531
  },
  {
    category: "Home appliances12",
    employeesNumber: 62,
    CarbonImpact: 532
  },
  {
    category: "Home appliances13",
    employeesNumber: 63,
    CarbonImpact: 533
  },
  {
    category: "Home appliances14",
    employeesNumber: 64,
    CarbonImpact: 534
  },
  {
    category: "Home appliances15",
    employeesNumber: 65,
    CarbonImpact: 535
  }
];
export default class Reporting extends DashboardPageEmployerController {
  // constructor(){
  //     su
  // }
  render() {
    return (
      <DashboardStyle>
        <Sidebar active={3}  history={this.props.history} handleChange={this.handleChange} employerData={this.state.employerData} handleChangeEmployerData={this.handleChangeEmployerData} handleUpdateEmployer={this.handleUpdateEmployer} errorMessage={this.state.errorMessage} fieldRequiredData={this.state.fieldRequiredData} handleClearData={this.handleClearData} switchData={this.state.switchData} errorMsgPasValidation={this.state.errorMsgPasValidation} handleEmployerData={this.handleEmployerData} contactUsData={this.state.contactUsData}/>
        <main>
          <div className="heading between">
            <h2 data-test-id="heading">Reporting - By Category</h2>
            <button className="exportBtn">Export Scope 3 Report</button>
          </div>
          <div className="body">
            <Grid container spacing={2}>
              {Array(4)
                .fill("")
                .map(_ => (
                  <Grid item xs={6} key={_}>
                    <Card
                      title="Home"
                      className="between"
                      btn={{ title: "1200 t CO2e" }}
                    >
                      <DataTable
                        align="center"
                        columns={[
                          { id: "category", label: "Category" },
                          { id: "employeesNumber", label: "No. Employees" },
                          {
                            id: "CarbonImpact",
                            label: "Carbon impact (t CO2e)"
                          }
                        ]}
                        rows={rows}
                        rowsPerPageOptions={[15, 20]}
                        rowsPerPage={15}
                      />
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </div>
        </main>
      </DashboardStyle>
    );
  }
}
