import React from "react";
import { Grid } from "@material-ui/core";
import Sidebar from "../../../components/src/sidebar.web";
import DataTable from "../../../components/src/DataTable.web";
import { DashboardStyle } from "./Engagement-Dashboard.web";
import Card from "../../../components/src/Card.web";
import DialogModel from "../../../components/src/Dialog.web";
import ExportContent from "../../../components/src/ExportContent.web";
import DashboardPageEmployerController from "./DashBordPageEmployerController.web";

type MyState = {
  isExport: boolean;
};
export default class ScopeReporting extends DashboardPageEmployerController {
  constructor(props: any) {
    super(props);
    this.handleExportModel = this.handleExportModel.bind(this);
  }
  handleExportModel() {
    this.setState(prev => ({ isExport: !prev.isExport }));
  }
  
  render() {
    return (
      <DashboardStyle>
        <Sidebar active={3} history={this.props.history} handleChange={this.handleChange} employerData={this.state.employerData} handleChangeEmployerData={this.handleChangeEmployerData} handleUpdateEmployer={this.handleUpdateEmployer} errorMessage={this.state.errorMessage} fieldRequiredData={this.state.fieldRequiredData} handleClearData={this.handleClearData} switchData={this.state.switchData} errorMsgPasValidation={this.state.errorMsgPasValidation} handleEmployerData={this.handleEmployerData} contactUsData={this.state.contactUsData}/>
        <main>
          <div className="heading between">
            <h2 data-test-id="heading">Scope 3 Reporting</h2>
            <button
              className="exportBtn"
              data-test-id="exportBtn"
              onClick={this.handleExportModel}
            >
              Export Scope 3 Report
            </button>
          </div>
          <div className="body">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  title="Scope 3 Reporting Direct Solutions"
                  className="between"
                  btn={{ title: "1200 t CO2e" }}
                >
                  <DataTable
                    align="center"
                    columns={[
                      { id: "subCat", label: "Scope 3 Category" },
                      { id: "focusArea", label: "Focus Area" },
                      { id: "description", label: "Category" },
                      { id: "employeesNumber", label: "No. Employees"},
                      {id: "CarbonImpact",label: "Carbon impact (t CO2e)"}
                    ]}
                    rows={this.state.claimData}
                    rowsPerPageOptions={[15, 20]}
                    rowsPerPage={15}

                  >


                  </DataTable>
                </Card>
              </Grid>
            </Grid>
          </div>
        </main>
        {this.state.isExport && (
          <DialogModel
            open={this.state.isExport}
            data-test-id={"DialogModel"}
            handleClose={this.handleExportModel}
          >
            <ExportContent handleClose={this.handleExportModel} />
          </DialogModel>
        )}
      </DashboardStyle>
    );
  }
}
