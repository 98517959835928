// Customizable Area Start
import React from "react";
import { designImage, logo, designImage1 } from "./assets";

import { Grid, Box, Button, Input } from "@material-ui/core";

import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { InputEye } from "../../../components/src/InputEye";
import Snackbar from '@material-ui/core/Snackbar';

const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const ParentGridComp = styled(Grid)({
    background: '#1B122E',
    '@media(max-width: 425px)': {
        background: 'rgb(37, 24, 64)',
        height: "-webkit-fill-available",
    },
});

const DesignGridComp = styled(Grid)({
  "@media(max-width: 767px)": {
    display: "none!important"
  }
});

const LoginGridComp = styled(Grid)({
  "@media(max-width: 425px)": {
    paddingLeft: "35px !important",
    paddingTop: "60px !important",
    fontFamily: "Figtree",
      paddingBottom: '200px !important',
      overflowX: 'hidden',

    "&:before":{content:'""',position:'absolute',
      background: `url(${designImage1})`,
      backgroundSize: "210px",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundPositionY: "196px",
      width:"100%", height:"100%",
      left: '0',
      top: '0',
      opacity: '.6',
    }
  },
});


const NewInput = styled(Input)({
  borderRadius: "8px",
  width: "70%",
  height: "48px",
  padding: "0px 16px",
  background: "#1B122E",
  color: "#FAFAFA",
  fontSize: "16px",
  fontFamily: "Figtree",
  marginBottom: '15px',
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: '14px',
    lineHeight: "16px",
    background: "#24183f",
    marginBottom: "10px",
  }
});

const LogoImage = styled("img")({
  paddingBottom: '88px',
  width: '200px',
  height: '51px',
  fontFamily: 'Figtree',
  marginTop: '106px',
  "@media (max-width: 425px)": {
    width: "150px",
    height: "38px",
    objectFit: 'cover',
    paddingBottom: "40px",
    marginTop: '35px'
  }
})


const NewPwdLabel = styled("label")({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FAFAFA",
  marginBottom: '10px',
  "@media (max-width: 425px)": {
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: '10px',
    position: 'relative'
  }
})

const NewPwdHeading = styled("p")({
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "40px",
  display: "flex",
  alignItems: "center",
  color: "#FAFAFA",
  paddingBottom: "24px",
  margin: '0px',
  fontFamily: 'Figtree',
  "@media (max-width: 425px)": {
    fontSize: '24px',
  }
})

const NewPwdErrors = styled("span")({
  color: '#EA7D7D',
  marginBottom: '10px',
  lineHeight: "24px",
  display: 'inline-block',
  fontSize: '16px',
  width: '550px',
  "@media (max-width: 425px)": {
    fontSize: "12px",
    lineHeight: "16px",
    width: 'fit-content',
    position: 'relative',
  }
})

const SaveButton = styled(Button)({
  background:
    "linear-gradient(0deg, rgba(250, 250, 250, 0.14), rgba(250, 250, 250, 0.14)), #1B122E",
  borderRadius: "24px 4px",
  width: "70%",
  height: "48px",
  color: "rgba(250, 250, 250, 0.4)",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  marginBottom: "34px",
  textTransform: "capitalize",
  marginTop: "20px",
  "&:hover": {
    backgroundColor: "#ECEF51",
    color: "#000"
  },
  "@media (max-width: 425px)": {
    width: "100%"
  }
});

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={themes}>
        <ParentGridComp container spacing={3}>
          <DesignGridComp item style={webStyle.designDiv} md={6}>
            <img style={webStyle.designsImage} src={designImage} alt="" />
          </DesignGridComp>
          <LoginGridComp item style={webStyle.contentBox} md={6}>
            <LogoImage src={logo} alt="" />
            <NewPwdHeading>Enter new password</NewPwdHeading>
            <Box sx={webStyle.inputsBox}>
              <NewPwdLabel htmlFor="password">
                Password
              </NewPwdLabel>
                  <NewInput
                    autoComplete="off"
                    data-test-id="txtPassword"
                    type={this.changePasswordType()}
                    name="password"
                    id="password"
                    placeholder="Enter password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    style={{ border: this.state.passwordError ? '1px solid #EA7D7D' : '1px solid #FAFAFA' }}
                    endAdornment={<InputEye dataid="btnShowHidePassword" enablePasswordField={this.state.enablePasswordField} handleShowPassword={this.enablePasswordShow} />}
                  />
              <NewPwdErrors
              >
                {this.state.passwordError}
              </NewPwdErrors>
            </Box>
            <Box sx={webStyle.inputsBox}>
              <NewPwdLabel htmlFor="confirmpassword">
              Confirm password
              </NewPwdLabel>
                  <NewInput
                    autoComplete="off"
                    data-test-id="txtConfirmPassword"
                    type={this.changeConfirmPasswordType()}
                    name="rePassword"
                    id="rePassword"
                    placeholder="Repeat password"
                    value={this.state.rePassword}
                    onChange={this.handleChangeRepassword}
                    style={{ border: this.state.rePasswordError ? '1px solid #EA7D7D' : '1px solid #FAFAFA' }}
                    endAdornment={<InputEye dataid="btnConfirmShowHidePassword" enablePasswordField={this.state.enableReTypePasswordField} handleShowPassword={this.enableConfirmPasswordShow} />}
                  />
              <NewPwdErrors
              >
                {this.state.rePasswordError}
              </NewPwdErrors>
            </Box>
            <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.popupMessage}
          autoHideDuration={2000}
          onClose={this.handleCloseSnackBar}
        >
        <this.Alert onClose={this.handleCloseSnackBar} severity={this.handleStatus()}>
          {this.state.responseData}
        </this.Alert>
        </Snackbar>
            <SaveButton type="submit" data-test-id="btnSubmit" onClick={() => this.handleResetPassword()}>Reset password</SaveButton>
          </LoginGridComp>
        </ParentGridComp>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

const webStyle = {
  backgroundColor: {
    background: "#251840"
  },
  designDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(0deg, rgba(250, 250, 250, 0.05), rgba(250, 250, 250, 0.05)), #1B122E",
    borderRadius: "0px 0px 400px 0px",
    padding: "112px 0px",
    fontFamily: "Figtree"
  },
  contentBox: {
    paddingLeft: "130px",
    width: "100%",
    paddingRight: "33px",
    "@media(max-width: 425px)": {
      paddingRight: "50px"
    }
  },
  inputsBox: {
    display: "flex",
    flexDirection: "column",
    gap: "-5px",
    "& .MuiInput-underline:after": {
      display: 'none !important',
    }
  },
  designsImage: {
    width: "400px",
    height: "800px"
  },
};
// Customizable Area End
