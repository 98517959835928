// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pointsEarnValue: number;
  rewardValue: number;
  cardDataValue: {
    title: string;
    item: {
      area: string;
      content: string;
      desc: string;
      id: number;
      status: string;
    }[];
  }[];
  totalRewradsvalue: any;
  totalRewardStatus: any;
  solution_id: any;
  account_id: any;
  approvedTitles: any[];
  notApprovedTitles: any[];
  pendingTitles: any[];
  dataTotal: any[];
  detailRewardData: {};
  dataArry:any;
  contact_us:any;
  // Customizable Area End
}

interface SS {
  //id: any;
}

export default class RewardsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  rewardCallId: string = "";
  statusCallId: string = "";
  solution_id1: string = "1";
  getSubCategoryById: string = "";
  getContactUs: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      pointsEarnValue: 40,
      rewardValue: 50,
      cardDataValue: [],
      totalRewradsvalue: [],
      solution_id: "1",
      account_id: "161",
      approvedTitles: [],
      notApprovedTitles: [],
      pendingTitles: [],
      dataTotal: [],
      totalRewardStatus: [],
      detailRewardData: {},
      dataArry:[],
      contact_us:{}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.handlCardData();
    this.handleAllStatus();
    this.handleRewardvalue();
    this.handleContactUs();
    // Access the data from the location state
    // Use the data as needed
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
  
    runEngine.debugLog("Message Received", message);
if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
  const apiRequestCallId = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

  switch (apiRequestCallId) {
    case this.rewardCallId:
      this.setState({ totalRewradsvalue: responseJson });
      break;

    case this.statusCallId:
      this.setState({ totalRewardStatus: responseJson.solutions.data });
      this.setState({ totalRewardStatus: this.state.totalRewardStatus.reverse() });

      responseJson.solutions.data.forEach((solution: any) => {
        this.setState({
          dataArry: solution.attributes.claims.data.sort((a: any, b: any) => b.id - a.id)
        });
        const firstClaim = this.state.dataArry[0];

        if (firstClaim) {
          const claimObject = {
            cliamid: firstClaim.attributes.id,
            content: solution.attributes.solution_title,
            status: firstClaim.attributes.status,
            description: solution.attributes.solution_description,
            id: solution.id,
          };
          this.state.dataTotal.push(claimObject);
          this.setState({ dataTotal: [...this.state.dataTotal] });
        }
      });

      break;

    case this.getContactUs:
      const contactus = responseJson.filter((data: any) => {
        return data.title === "Contact Us";
      });
      this.setState({ contact_us: contactus[0].description });
      break;

    default:
      // Handle a default case if necessary
  }

  this.setState((prevState) => ({
    dataTotal: prevState.dataTotal.map((item) => {
      switch (item.status) {
        case "approved":
          return { ...item, status: "Approved" };
        case "not_approved":
          return { ...item, status: "Not Approved" };
        case "pending":
          return { ...item, status: "Under review" };
        default:
          return item;
      }
    }),
  }));

  this.setState({
    dataTotal: [...this.state.dataTotal],
  });

  this.state.dataTotal.sort((a, b) => b.cliamid - a.cliamid);
  this.setState({
    dataTotal: [...this.state.dataTotal],
  });
}

  }


  handlCardData = () => {
    const cardData = [
      {
        title: "Your Claims",
        item: [
          {
            id: 1,
            status: "Approved",
            content:
              "Choose bio-based insulation to avoid unnecessary chemicals",
            area: "Area",
            desc: "We could not verify your progress, due to (problem 1) and (problem 2). We recommend you to re submit the progress of your commitment or contact Ezeo support.",
          },
          {
            id: 2,
            status: "Not Approved",
            content:
              "Choose refurbished/remanufactured appliances instead of factory-new appliances",
            area: "Area",
            desc: "We could not verify your progress, due to (problem 1) and (problem 2). We recommend you to re submit the progress of your commitment or contact Ezeo support.",
          },
          {
            id: 3,
            status: "Approved",
            content:
              "Choose refurbished/remanufactured appliances instead of factory-new appliances",
            area: "Area",
            desc: "We could not verify your progress, due to (problem 1) and (problem 2). We recommend you to re submit the progress of your commitment or contact Ezeo support.",
          },
          {
            id: 4,
            status: "Under review",
            content:
              "Choose the energy efficient or water efficient run options",
            area: "Area",
            desc: "We could not verify your progress, due to (problem 1) and (problem 2). We recommend you to re submit the progress of your commitment or contact Ezeo support.",
          },
        ],
      },
    ];

    this.setState({ cardDataValue: cardData });
  };

  handleRewardsStatus = (data: {
    area: string;
    content: string;
    desc: string;
    id: number;
    status: string;
  }) => {
    let details = this.state.totalRewardStatus.find(
      (ele: any) => ele.id === data.id
    );
    let description = { ...data, ...details.attributes };
    
    this.props.history.push({
      pathname: "RewardsStatus",
      state: {
        breadCrum: [
          { name: "Rewards", path: "Rewards" },
          {
            name: data.content,
            path: "RewardsStatus",
            id: data.id,
            attributes: description,
          },
        ],
        id: data.id,
      },
    });
  };

  handleReedim = () => {   
    this.props.history.push({
      pathname: "RewardsStatus",
      state: {
        breadCrum: [
          { name: "Rewards", path: "Rewards" },
          { name: "Payout", path: "RewardsStatus", id:'22', attributes:this.state.totalRewardStatus[0].attributes},
        ],
        headerText: "",
        redeem: true,
      },
    });
  };

  handleRewardvalue = () => {
    const header = {
      token: localStorage.getItem("user_token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rewardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reward/rewards/show_point_redeems`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.rewardCallId;
  };
  handleAllStatus = () => {
    const header = {
      token: localStorage.getItem("user_token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.statusCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_solutions/solutions"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.statusCallId;
  };

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }


  // Customizable Area End
}
