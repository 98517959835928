import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";


export const configJSON = require("./config");

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  commingSoonArray: any;
  pointsEarnValue: number;
  rewardValue: number;
  pointsGraph: number;
  totalPoints: number;
  pointsCommited: number;
  solutionId: number;
  accountId:number;
  getProfileTypeData:any;
  profielType:any;
  actData:{}[];
  changeData:{}[];
  planData:{}[];
  attributesData:any;
  userName:any;
  redirectDetailPage:boolean,
  firstName: string,
  emailData: string,
  account_Id: number,
  contact_us: any;

  // isCheck: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DashboardPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrentPointApi: string = "";
  getEmployeePointApi: string = "";
  getPointRedeem: string = "";
  getAcconuttypeID:string="";
  getSolutionCommit: string="";
  getContactUs: string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      commingSoonArray: [],
      rewardValue: 0,
      pointsEarnValue: 0,
      pointsGraph: 25,
      totalPoints: 0,
      pointsCommited: 0,
      getProfileTypeData:[],
      userName:[],
      profielType:"",
      solutionId:1,
      accountId:161,
      actData: [],
      changeData: [],
      planData: [],
      attributesData: [],
      redirectDetailPage:false,
      firstName: "",
      emailData: "",
      account_Id: 0,
      contact_us: {},
      // isCheck: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    await super.componentDidMount();
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("user_token")) ||
      "";
    this.setState({ token: token });
    this.getCurrentPoint();
    this.getEmployeePointApiCall();
    this.getPointRedeemApi();
    this.handleProfileLogo()
    this.handleSolutionCommit();
    this.handleContactUs();
    // Access the data from the location state
    // Use the data as needed
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.callAfterApiCall(apiRequestCallId, responseJson);
    }
    if (apiRequestCallId == this.getAcconuttypeID) {
      this.setState({ getProfileTypeData:responseJson.data})
      this.setState({ profielType:responseJson.data.attributes.profile_type})
      this.setState({ userName:responseJson.data.attributes})
      this.setState({ firstName: responseJson.data.attributes.first_name})
      this.setState({ emailData: responseJson.data.attributes.email})

      localStorage.setItem('profielType' ,this.state.profielType)
      localStorage.setItem('firstName' ,this.state.firstName)
      localStorage.setItem('email' ,this.state.emailData)
    }
    // Customizable Area Start
    // Customizable Area End
  }
callAfterApiCall = (apiRequestCallId: string, responseJson: any) => {
  
      switch (apiRequestCallId) {
        case this.getCurrentPointApi:
          if (!responseJson.error) {
            // this.setState({rewardValue: responseJson.data.reward_value, pointsEarnValue: responseJson.data.point_earned})
            return responseJson;
          }
          break;
    
        case this.getEmployeePointApi:
          if (!responseJson.error) {
            const points = responseJson.result.split("/");
            this.setState({
              totalPoints: Number(points[1]),
              pointsCommited: Number(points[0])
            });
            return responseJson;
          }
          break;
    
        case this.getPointRedeem:
          if (!responseJson.error) {
            this.setState({
              pointsEarnValue: responseJson.point_earned,
              rewardValue: responseJson.reward_value
            });
            return responseJson;
          }
          break;
    
        case this.getSolutionCommit:
          if (!responseJson.error) {
            this.setState({
              actData: responseJson.data?.act,
              changeData: responseJson.data?.change,
              planData: responseJson.data?.plan,
              attributesData: responseJson.solutions.data,
              account_Id: responseJson.current_employee_user
            });
            return responseJson;
          }
          break;
    
        case this.getContactUs:
          if (!responseJson.error) {
            const contactus = responseJson.filter((data: any) => {
              return data.title === "Contact Us";
            });
            this.setState({ contact_us: contactus[0].description });
            return responseJson;
          }
          break;
    
        default:
          break;
      }
    
  };
  // Customizable Area Endthis.handleProgressbar(totalPoints, pointsCommited)

  // Customizable Area Start

  getCurrentPoint = () => {
    const URL = `${configJSON.getCurrentPoint}?solution_id=${this.state.solutionId}&account_id=${this.state.accountId}`;
    this.getCurrentPointApi = sendAPIRequest(URL, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  };

  getEmployeePointApiCall = () => {
    this.getEmployeePointApi = sendAPIRequest(configJSON.getEmployeePoint, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  }

  getPointRedeemApi = () => {
    this.getPointRedeem = sendAPIRequest(configJSON.showRedeemPoint, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  }

  handleSolutionCommit = () => {
    this.getSolutionCommit = sendAPIRequest(configJSON.solutionCommitments, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  }

  handleClick = () => {
    this.props.history.push({
      pathname: "Categories"
    });
  };

  handleProgressbar = (total: number, commited: number) => {
    const pr = (commited * 100) / total;
    return pr;
  };

  handleReportProgress = (data: any) => {
    let details = this.state.attributesData.find((ele: any) => ele.id == data?.solution_id);
    let description = { ...data, ...details?.attributes };
    this.props.history.push({
      pathname: "Details",
      state: {
        breadCrum: [
          { name: "Focus area", path: "Categories" },
          { name: details?.attributes?.sub_categories[0].category_name[0], path: "SubCategories", id: details?.attributes.sub_categories[0].category_id[0] },
          {
            name: details?.attributes?.sub_categories[0].sub_category.name,
            path: "Details",
            attributes: description,
          },
        ],
        headerText: details?.attributes.sub_categories[0].category_name[0],
        //account_id: details?.attributes.claims.data[0].attributes.account_id,
        account_id: this.state.account_Id
      },
      redirectDetailPage:true
    });
  };

  handleReedim = () => {
    const attdata = {"attributes": {
      "id": 4,
      "solution_title": "Test1",
      "solution_description": "Hi this is test1",
      "ezeo_reward_points": "5000",
      "solution_horizon": "act",
      "earnings": [
          {
              "id": 9,
              "title": "Test1",
              "value": "5000",
              "solution_id": 4,
              "created_at": "2023-08-07T14:45:15.992+01:00",
              "updated_at": "2023-08-07T14:45:15.992+01:00"
          }
      ],
      "claims": {
          "data": [
              {
                  "id": "688",
                  "type": "claim",
                  "attributes": {
                      "id": 688,
                      "account_id": 669,
                      "solution_id": 4,
                      "status": "approved",
                      "created_at": "2023-09-13T05:02:40.088+01:00",
                      "updated_at": "2023-09-13T05:33:59.898+01:00"
                  }
              }
          ]
      },
      "sub_categories": [
          {
              "sub_category": {
                  "id": 39,
                  "name": "Personal Flight",
                  "created_at": "2023-07-03T09:39:15.704+01:00",
                  "updated_at": "2023-07-06T15:57:06.947+01:00",
                  "parent_id": null,
                  "rank": null
              },
              "category_name": [
                  "Travel"
              ],
              "category_id": [
                  22
              ]
          }
      ]
  }}
    this.props.history.push({
      pathname: "RewardsStatus",
      state: {
        breadCrum: [
          { name: "Rewards", path: "Rewards" },
          { name: "Payout", path: "RewardsStatus", id:"22", attributes: attdata.attributes },
        ],
        headerText: "",
        redeem: true,
      },
    });
  };

  handleProfileLogo = () => {
    const header = {
      token: localStorage.getItem("user_token"),
 };
      const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAcconuttypeID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/user_profile_type'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getAcconuttypeID;
  };

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }

  // handleToggleNotification = () => {
  //   this.setState({ isCheck: !this.state.isCheck})
  //   console.log("9999+++",this.state.isCheck)
  // }
  // Customizable Area End
}
