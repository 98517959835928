// Customizable Area Start
import React from "react";
import { designImage, logo, designImage1 } from "./assets";

import { Grid, Box, Button, Input } from "@material-ui/core";

import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';

const themess = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const ParentGridComps = styled(Grid)({
    background: '#1B122E',
    '@media(max-width: 425px)': {
        background: 'rgb(37, 24, 64)',
        height: "-webkit-fill-available",
    },
});
const DesignGridComps = styled(Grid)({
  "@media(max-width: 767px)": {
    display: "none!important"
  }
});
const LoginGridComps = styled(Grid)({
  "@media(max-width: 425px)": {
    paddingLeft: "35px!important",
    paddingTop: "40px !important",
    fontFamily: "Figtree",
    paddingBottom: '200px !important',
    "&:before":{content:'""',position:'absolute',
      background: `url(${designImage1})`,
      backgroundSize: "210px",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundPositionY: "196px",
      width:"100%", height:"100%",
      left: '0',
      top: '0',
      opacity: '.6',
    }
  }
});

const StyledInputComps = styled(Input)({
  borderRadius: "8px",
  width: "70%",
  height: "48px",
  padding: "0px 16px",
  background:"#1B122E",
  color: "#FAFAFA",
  fontSize: "16px",
  fontFamily: "Figtree",
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: "14px",
    background: "#24183f",
  },
});

const LogoImage = styled("img")({
  paddingBottom: '88px',
  width: '200px',
  height: '51px',
  fontFamily: 'Figtree',
  marginTop: '106px',
  "@media (max-width: 425px)": {
    width: "150px",
    height: "38px",
    objectFit: 'cover',
    paddingBottom: "40px",
    marginTop: '35px'
  }
})


const LoginLabel = styled("label")({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FAFAFA",
  "@media (max-width: 425px)": {
    fontSize: "12px",
    lineHeight: "12px",
    position: 'relative'
  }
})

const LoginHeading = styled("p")({
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "40px",
  display: "flex",
  alignItems: "center",
  color: "#FAFAFA",
  paddingBottom: "24px",
  margin: '0px',
  fontFamily: 'Figtree',
  "@media (max-width: 425px)": {
    fontSize: '24px',
  }
})


const LoginErrors = styled("span")({
  color: '#EA7D7D',
  marginBottom: '10px',
  lineHeight: "24px",
  display: 'inline-block',
  fontSize: '16px',
  "@media (max-width: 425px)": {
    fontSize: "12px",
    lineHeight: "16px",
    position: 'relative'
  }
})

const OTPButton = styled(Button)({
  background:
    "linear-gradient(0deg, rgba(250, 250, 250, 0.14), rgba(250, 250, 250, 0.14)), #1B122E",
  borderRadius: "24px 4px",
  width: "70%",
  height: "48px",
  color: "rgba(250, 250, 250, 0.4)",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  marginBottom: "34px",
  textTransform: "capitalize",
  marginTop: "26px",
  "&:hover": {
    backgroundColor: "#ECEF51",
    color: "#000"
  },
  "@media (max-width: 425px)": {
    width: "100%"
  }
});

import ForgotPasswordController, {Props} from "./ForgotPasswordController";

export default class ForgotPasswordOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={themess}>
        <ParentGridComps container spacing={3}>
          <DesignGridComps item style={webStyle.designDiv} md={6}>
            <img style={webStyle.designImage} src={designImage} alt="" />
          </DesignGridComps>
          <LoginGridComps item style={webStyle.contentBox} md={6}>
            <LogoImage src={logo} alt="" />
            <LoginHeading>Forgot password</LoginHeading>
            <Box sx={webStyle.inputBox}>
              <LoginLabel htmlFor="otp">
                Verification code
              </LoginLabel>
                  <StyledInputComps
                    autoComplete="off"
                    placeholder="Enter verification code"
                    data-test-id="txtInputOtp"
                    value={this.state.passwordOtp}
                    onChange={this.handlePasswordOtp}
                    style={{ border: this.state.passwordOtpError ? '1px solid #EA7D7D' : '1px solid #FAFAFA' }}
                  />
              <LoginErrors
              >
                {this.state.passwordOtpError}
              </LoginErrors>
            </Box>
            <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.popupMessage}
          autoHideDuration={2000}
          onClose={this.handleCloseSnackBar}
        >
        <this.Alert onClose={this.handleCloseSnackBar} severity="error">
          {this.state.responseData}
        </this.Alert>
        </Snackbar>
            <OTPButton type="submit" data-test-id="btnSubmit" onClick={() => this.sendVerificationEmailOtp()}>Next</OTPButton>
          </LoginGridComps>
        </ParentGridComps>
      </ThemeProvider>

    );
  }
}

const webStyle = {
  backgroundColor: {
    background: "#251840"
  },
  designDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(0deg, rgba(250, 250, 250, 0.05), rgba(250, 250, 250, 0.05)), #1B122E",
    borderRadius: "0px 0px 400px 0px",
    padding: "112px 0px",
    fontFamily: "Figtree"
  },
  contentBox: {
    paddingLeft: "130px",
    width: "100%",
    paddingRight: "33px",
    "@media(max-width: 425px)": {
      paddingRight: "50px"
    }
  },
  inputBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "& .MuiInput-underline:after": {
      display: 'none !important',
    }
  },
  designImage: {
    width: "400px",
    height: "800px"
  },
};
// Customizable Area End
