import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";

export const configJSON = require("./config");

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataCard: {id:string, description:string, name:string, title:string };
  contact_us: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAboutUs: string = "";
  getContactUs: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      dataCard: {id:"", description:"", name:"", title:""},
      contact_us:{}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getAboutUsApi();
    this.handleContactUs();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.callAfterApiCall(apiRequestCallId, responseJson);
    }
    // Customizable Area Start
    // Customizable Area End
  }

  callAfterApiCall = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getAboutUs) {
      if (!responseJson.error) {
      const aboutUs = responseJson.filter((data: any) => {
        return data.title === "About Us"        
      })
        this.setState({ dataCard: aboutUs[0]})       
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }   
    if (apiRequestCallId === this.getContactUs) {
      if (!responseJson.error) {
      const contactus = responseJson.filter((data: any) => {
        return data.title === "Contact Us"        
      })
        this.setState({ contact_us: contactus[0].description });  
      } 
    } 
  };

  // Customizable Area Start

  getAboutUsApi = () => {
    this.getAboutUs = sendAPIRequest(configJSON.about);
  };

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }

  // Customizable Area End
}
