/* eslint-disable react-native/no-inline-styles */
import React from "react";

// Customizable Area Start
import { Box, Typography, Card, Button, Divider } from "@material-ui/core";
import DashboardPageController, { Props } from "./DashboardPageController.web";
import Navbar from "../../../components/src/Navbar.web";
import { star, background } from "./assets";
import { configJSON } from "./DashboardController";
import { styled } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
// Customizable Area End

const DashboardWrapper = styled("div")({
  backgroundColor: "#1B122E",
  height: "fit-content",
  "& .dashboard-firstpart": {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPositionX: "center",
    display: "flex",
    width: "90%",
    margin: "50px auto",
    "@media (max-width:600px)": {
      flexWrap: "wrap",
      backgroundPositionY: "center"
    }
  },
  "& .rewards-title": {
    position: "relative",
    display: "block",
    fontFamily: "Figtree, sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    color: "white",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px"
    }
  },
  "& .starLogo": {
    width: "18px",
    position: "absolute",
    top: "-15px",
    "@media (max-width:600px)": {
      width: "18px"
    }
  },
  "& .top-cardcontent": {
    backgroundColor: "#261E38",
    padding: "24px",
    width: "100%",
    borderRadius: "24px",
    marginTop: "24px",
    "@media (max-width:600px)": {
      width: "88%",
      margin: "0px auto",
      fontSize: "16px",
      fontWeight: 700
    },
    "@media (max-width:380px)": {
      padding: "20px"
    }
  },
  "& .rewards-point-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .rewards-point": {
      width: "48%",
      textAlign: "center",
      "& .point-title": {
        color: "#fafafacc",
        fontFamily: "Figtree, sans-serif",
        fontSize: "12px",
        fontWeight: 500
      },
      "& .point-value": {
        color: "#FC6D52",
        fontFamily: "Figtree, sans-serif",
        fontSize: "24px",
        fontWeight: 700,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    },
    "& .rewards-line": {
      width: "1px",
      height: "56px",
      background: "#3b334c"
    }
  },
  "& .submit-btn": {
    width: "100%",
    backgroundColor: "yellow",
    borderRadius: "24px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "14px",
    color: "#1B122E",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
    marginTop: "16px",
    position: "unset"
  },
  "& .submit-btn-notactive": {
    width: "100%",
    backgroundColor: "#3a324a",
    borderRadius: "24px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "14px",
    color: "#94909d",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
    marginTop: "16px",
    position: "unset"
  },
  "& .progress-indicatior": {
    color: "#FAFAFA",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px"
  },
  "& .inner-circle": {
    backgroundColor: "#181029",
    width: "70%",
    height: "70%",
    borderRadius: "50%"
  },
  "& .first-cardContent": {
    marginTop: "24px"
  },
  "& .dashboard-secoundpart": {
    width: "90%",
    margin: "50px auto"
  },
  "& .cardtitle-red": {
    color: "#FC6D52",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "32px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 700
    }
  },
  "& .cardtitle-yellow": {
    color: "#ec9925",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "32px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 700,
    }
  },
  "& .cardtitle-blue": {
    color: "#207479",
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "32px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 700
    }
  },
  "& .card-content-red": {
    width: "100%",
    border: "1px solid #cf5b4b",
    backgroundColor: "#2d1931",
    borderRadius: "24px",
    marginTop: "-13px",
    "@media (max-width:600px)": {
      marginTop:"-18px"
    }
  },
  "& .card-content-yellow": {
    width: "100%",
    border: "1px solid #cf5b4b",
    backgroundColor: "#2F252F",
    borderRadius: "24px",
    marginTop: "-13px",
    "@media (max-width:600px)": {
      marginTop:"-18px"
    }
  },
  "& .card-content-blue": {
    width: "100%",
    border: "1px solid #1C233B",
    backgroundColor: "#1C233B",
    borderRadius: "24px",
    marginTop: "-13px",
    "@media (max-width:600px)": {
      marginTop:"-18px"
    },
  },
  "& .yellow-border": {
    border: "1px solid #ec9925 !important"
  },
  "& .red-border": {
    border: "1px solid #FC6D52 !important",
  },
  "& .blue-border": {
    border: "1px solid #1F7278 !important",
  },
  "& .no-data": {
    padding:"30px",
    color: "#FAFAFA"
  },
  "& .cardfirstdata": {
    fontFamily: "Figtree, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    height: "20px",
    color: "rgba(250, 250, 250, 0.6)",
    //color:"rgba(250, 250, 250, 0.6)",
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .cardfirstdata-mobile": {
    fontFamily: "Figtree, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    height: "20px",
    color: "rgba(250, 250, 250, 0.6)",
    display: "none",
    "@media (max-width:600px)": {
      display: "inline-block"
    },
    "@media (max-width:350px)": {
      fontSize: "13px"
    }
  },
  "& .cardseconddata": {
    marginTop: "6px",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 500,
    },
    "@media (max-width:350px)": {
      fontSize: "13px"
    }
  },
  "& .buttoncontent": {
    textTransform: "unset",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "25px",
    width: "180px",
    color: "#FAFAFA",
    //color:"rgba(250, 250, 250, 0.6)",
    position: "unset",
    margin: "12px 4px",
    padding: "8px",
    border: "2px solid rgba(250, 250, 250, 0.8)",
    "@media (max-width:600px)": {
      fontSize: "12px",
      fontWeight: 500,
      width: "130px",
      padding: "7px",
    }
  },
  "& .dividercontent": {
    margin: "0 31px",
    backgroundColor: "#3B1F33"
    //backgroundColor: "rgba(250, 250, 250, 0.12)"
  },
  "& .cardlastdata": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "rgba(250, 250, 250, 0.6)",
    "@media (max-width:600px)": {
      fontSize: "12px",
      fontWeight: 400,
    },
    "@media (max-width:350px)": {
      fontSize: "11px"
    }
  },
  "& .rewards-card": {
    width: "25%",
    "@media (max-width:1080px)": {
      width: "35%"
    },
    "@media (max-width:800px)": {
      width: "50%"
    },
    "@media (max-width:600px)": {
      width: "99%"
    }
  },
  "& .points-card": {
    width: "25%",
    "@media (max-width:1080px)": {
      width: "30%"
    },
    "@media (max-width:800px)": {
      width: "40%"
    },
    "@media (max-width:600px)": {
      width: "100%"
    }
  },
  "& .circuler-progress": {
    "@media (max-width:600px)": {
      display: "none"
    }
  },
  "& .points-mobile": {
    backgroundColor: "#261E38",
    height: "8px",
    borderRadius: "7px",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#ffff00"
    }
  },
  "& .line-progressbaar": {
    textAlign: "right",
    display: "none",
    "@media (max-width:600px)": {
      display: "block"
    }
  },
  "& .main-box": {
    padding: "0px",
    marginTop: "23px",
    "@media (max-width:600px)": {
      //padding: "8px 16px"
    }
  },
  "& .view-details": {
    textAlign: "center",
    margin: "71px auto",
    width: "50%",
    "@media (max-width:600px)": {
      width: "100%",
      margin: "16px auto",
      textAlign: "left"
    }
  },
  "& .description-text": {
    color: "#fafafa99"
  },
  "& .view-solution-button": {
    textTransform: "unset",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "25px",
    width: "180px",
    borderColor: "#FAFAFA",
    color: "#FAFAFA",
    position: "unset",
    margin: "42px 4px",
    padding: "10px",
    "@media (max-width:600px)": {
      fontSize: "12px",
      fontWeight: 500,
      width: "130px",
      display: "block",
      margin: "24px auto"
    }
  },
  "& .card-content-data": {
    marginTop:"23px",
    "@media (max-width:600px)": {
      marginTop:"13px",
    }
  },
});

export default class DashboardPage extends DashboardPageController {
  constructor(props: Props) {
    super(props);
  }

  titleColor = (category: string) => {
    if(category === 'Change'){
      return 'cardtitle-yellow';
    }else if(category === 'Act'){
      return 'cardtitle-red'
    }else{
      return 'cardtitle-blue';    
    }
  }

  borderColor = (category: string) => {
    if(category === 'Change'){
      return 'yellow-border card-content-yellow';
    }else if(category === 'Act'){
      return 'red-border card-content-red'
    }else{
      return 'blue-border card-content-blue';    
    }
  }

  generateContent(data:any, category:any) {
    return (
      data &&
      data.length > 0 && (
        <>
          <Typography className={this.titleColor(category)}>{category} now</Typography>
          <CardContent className="main-box">
            <Card className={this.borderColor(category)}>
              {data.map((item:any, index:any, array:any) => (
                <Box key={index + 1}>
                  <Box className="card-content-data">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          padding: "0px 25px",
                          marginBottom: "5px"
                        }}
                        data-test-id="details-div"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography
                              className="cardseconddata"
                              component="div"
                            >
                              {item.solution_title}
                            </Typography>
                            <Typography
                              className="cardfirstdata"
                              component="div"
                            >
                              Points : {item?.solution_points_earned === null ? "0" : item?.solution_points_earned}
                            </Typography>
                            <Typography
                              className="cardfirstdata-mobile"
                              component="div"
                            >
                              Points : {item?.solution_points_earned === null ? "0" : item?.solution_points_earned}{" "}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                          <Typography
                            className="cardlastdata"
                            component="div"
                          >
                            Status : {item.status}
                          </Typography>
                          <Button
                            className="buttoncontent"
                            variant="outlined"
                            data-test-id="report-progress-btn-plan"
                            onClick={() => {
                              this.handleReportProgress(item);
                            }}
                          >
                            {item.status === "Under Review"
                              ? "View Details"
                              : "Report Progress"}
                          </Button>
                        </Box>
                      </Box>
                      {array.length - 1 !== index && (
                        <Divider
                          className="dividercontent"
                          variant="middle"
                        />
                      )}
                    </Box>
                  </Box>
              ))}
            </Card>
          </CardContent>
        </>
      )
    );
  }

  render() {
    const {
      totalPoints,
      pointsCommited,
      pointsEarnValue,
      rewardValue,
      actData,
      changeData,
      planData
    } = this.state;

    return (
      <DashboardWrapper>
        <Navbar
          // @ts-ignore
          history={this.props.history}
          params={"EmployeeHome"}
          headerText={"Dashboard"}
          profielType={this.state.profielType}
          userName={this.state.userName}
          contact_us={this.state.contact_us}
        />
        <Box className="dashboard-firstpart">
          <Box className="points-card">
            <Typography variant="h5" className="rewards-title">
              {configJSON.CommitedPoints}
            </Typography>
            <Box className="first-cardContent">
              <Box
                position="relative"
                display="inline-flex"
                className="circuler-progress"
              >
                <CircularProgress
                  style={{
                    backgroundColor: "#261E38",
                    borderRadius: "50%",
                    color: "#ffff00"
                  }}
                  thickness={7}
                  size={"154px"}
                  variant="determinate"
                  value={
                    this.handleProgressbar(totalPoints, pointsCommited)
                      ? this.handleProgressbar(totalPoints, pointsCommited)
                      : 1
                  }
                />
                <Box
                  top={23}
                  left={23}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="inner-circle"
                >
                  <Typography component="div" className="progress-indicatior">
                    {pointsCommited + "/" + totalPoints}
                  </Typography>
                </Box>
              </Box>
              <Box className="line-progressbaar">
                <LinearProgress
                  variant="determinate"
                  value={
                    this.handleProgressbar(totalPoints, pointsCommited)
                      ? this.handleProgressbar(totalPoints, pointsCommited)
                      : 0
                  }
                  className="points-mobile"
                />
                <Typography
                  component="div"
                  className="progress-indicatior"
                  style={{ padding: "10px 0px" }}
                >
                  {pointsCommited + "/" + totalPoints}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="rewards-card">
            <Typography variant="h5" className="rewards-title">
              {configJSON.Rewards}
              <img className="starLogo" src={star} alt="star" />
            </Typography>
            <Card className="top-cardcontent">
              <Box className="rewards-point-wrapper">
                <div className="rewards-point">
                  <Typography className="point-title">
                    {configJSON.point_earned}
                  </Typography>
                  <Typography className="point-value">
                    {pointsEarnValue}
                  </Typography>
                </div>
                <div className="rewards-line" />
                <div className="rewards-point">
                  <Typography className="point-title">
                    {configJSON.RewardsValue}
                  </Typography>
                  <Typography className="point-value">
                    {rewardValue}
                    <span style={{ fontSize: "14px" }}>
                      {configJSON.currency_sign}
                    </span>
                  </Typography>
                </div>
              </Box>
              <Button
                variant="contained"
                className={
                  pointsEarnValue !== 0 ? "submit-btn" : "submit-btn-notactive"
                }
                data-test-id="reedem-button"
                disabled={pointsEarnValue !== 0 ? false : true}
                onClick={this.handleReedim}
              >
                {configJSON.Redeem}
              </Button>
            </Card>
          </Box>
        </Box>
        <Box className="dashboard-secoundpart">
          <Typography variant="h5" className="rewards-title">
            {configJSON.commitments}
          </Typography>
          <Box>
            {(actData?.length > 0 || changeData?.length > 0 || planData?.length > 0 ) ? (
              <Box>
              {this.generateContent(this.state.actData, 'Act')}
              {this.generateContent(this.state.changeData, 'Change')}
              {this.generateContent(this.state.planData, 'Plan')}
            </Box>   
            ) : (
              <Box className="view-details">
                <Typography className="description-text">
                  You have not committed to any of the Solutions.
                  <br />
                  pick an action and make a difference !
                </Typography>
                <Button
                  className="view-solution-button"
                  variant="outlined"
                  data-test-id="details-btn"
                  onClick={this.handleClick}
                >
                  {configJSON.View_Solutions}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </DashboardWrapper>
    );
  }
}