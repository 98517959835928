import React, { Component } from 'react';
import { styled } from "@material-ui/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
type MyProps = { params: string; history: any; breadcrumData: any; };
type MyState = { params: string; openSettingModal: boolean, subCategory: string; breadcrumDataValue: { name: string, path: string }[]; };

const BreadcrumBlock = styled(Breadcrumbs)({
    display: "flex",
    "@media (max-width:600px)": {
        width: "100%"
    },
    '& .breadcrumcontent': {
        color: "#FAFAFA",
        fontSize: "14px",
        fontWeight: 500,
        height: "20px",
        textDecoration: "none",
        "@media (max-width:600px)": {
            marginTop: 0,
            height: 0,
        },
    },
    '& .linkcolor': {
        fontFamily: "Figtree, sans-serif",
        color: "rgba(250, 250, 250, 0.6)",
        textDecoration: "none",
        display: "flex",
        fontWeight: 500,
        alignItems: "center",
        fontSize: "14px",
        cursor:"pointer",
        "@media(max-width:600px)": {
            fontSize: "12px"
        },
    },
    '& .rigthArrow': {
        marginRight: "10px"
    },
    '& .active': {
        fontFamily: "Figtree, sans-serif",
        color: "rgba(250, 250, 250, 1)",
        textDecoration: "none",
    },
    '& .sublinkcolor': {
        fontFamily: "Figtree, sans-serif",
        color: "#FAFAFA",
        textDecoration: "none",
    },
})

class BreadCrumb extends Component<MyProps, MyState>{

    constructor(props: any) {
        super(props);
        this.state = {
            params: this.props.params,
            openSettingModal: false,
            subCategory: '',
            breadcrumDataValue: []
        };
    }

    handleRedirection = (path: string) => {
        const temp = [...this.props.breadcrumData]
        const index = temp.findIndex(x => x.path === path);
        temp.length = index + 1;
        this.props.history.push({
            pathname: path,
            state: {
                breadCrum: [...temp],
                headerText: this.props.history.location.state.headerText
            }
        })
    }


    render() {
        return (
            <>
                <BreadcrumBlock separator="" aria-label="breadcrumb" className="breadcrumcontent" >
                    {[...this.props.breadcrumData].map((data: { path: string, name: string }, index: number) => {
                        return (
                            <span color="inherit" className={index === [...this.props.breadcrumData].length - 1 ? 'linkcolor active' : 'linkcolor'} key={data.name} onClick={() => this.handleRedirection(data.path)}>
                                {index ? <NavigateNextIcon fontSize="small" className='rigthArrow' /> : ""} {data.name.substring(0,66)}{data.name.length>66?"...":""}
                            </span>

                        )
                    })}
                </BreadcrumBlock>
            </>

        )
    }
}
export default BreadCrumb;
