export const logo = require('./assets/ezeologo.png')
export const userlogo2 = require('./assets/user2.png')
export const userlogo3= require('./assets/user3.png')
export const userlogo = require('./assets/userlogo.png')
export const homelogo = require('./assets/home.png')
export const toggle = require('./assets/toggle3x.png')
export const cross = require('./assets/cross.png')
export const userProfileMobile = require('./assets/userProfileMobile.png')
export const focusArea = require('./assets/focusArea.png')
export const aboutEzeo = require('./assets/aboutEzeo.png')
export const rewards = require('./assets/rewards.png')
export const contactUs = require('./assets/contactUs.png')
export const settings = require('./assets/settings.png')
export const logOut = require('./assets/logOut.png')
export const walkthrough_1 = require('./assets/walkthrough_1.png')
export const walkthrough_2 = require('./assets/walkthrough_2.png')
export const walkthrough_3 = require('./assets/walkthrough_3.png')
export const walkthrough_4 = require('./assets/walkthrough_4.png')
export const next = require('./assets/next.png')
export const previous = require('./assets/previous.png')
export const bg_img = require('./assets/bg_img.png')
export const home = require('./assets/homelog1.png')