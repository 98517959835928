// Customizable Area Start
import React, { CSSProperties } from "react";
import { designImage, logo, designImage1 } from "./assets";

import { Grid, Box, Button, Input, Typography } from "@material-ui/core";

import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';

const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const ParentGrids = styled(Grid)({
  background: '#1B122E',
  '@media(max-width: 425px)': {
      background: 'rgb(37, 24, 64)',
      height: "-webkit-fill-available",
  },
});

const DesignGrids = styled(Grid)({
  "@media(max-width: 767px)": {
    display: "none!important"
  }
});
const LoginGrids = styled(Grid)({
  "@media(max-width: 425px)": {
    paddingLeft: "35px!important",
    paddingTop: "30px !important",
    fontFamily: "Figtree",
    paddingBottom: '200px !important',
    "&:before":{content:'""',position:'absolute',
      background: `url(${designImage1})`,
      backgroundSize: "210px",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "center",
      backgroundPositionY: "196px",
      width:"100%", height:"100%",
      left: '0',
      top: '0',
      opacity: '.6',
    }
  }
});

const ForgotInput = styled(Input)({
  borderRadius: "8px",
  width: "70%",
  height: "48px",
  padding: "0px 16px",
  background:"#1B122E",
  color: "#FAFAFA",
  fontSize: "16px",
  fontFamily: "Figtree",
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: "14px",
    background: "#24183f",
  },
});

const LogoImage = styled("img")({
  paddingBottom: '88px',
  width: '200px',
  height: '51px',
  fontFamily: 'Figtree',
  marginTop: '106px',
  "@media (max-width: 425px)": {
    width: "150px",
    height: "38px",
    objectFit: 'cover',
    paddingBottom: "40px",
    marginTop: '35px'
  }
})

const ForgotLabel = styled("label")({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FAFAFA",
  "@media (max-width: 425px)": {
    fontSize: "12px",
    lineHeight: "16px",
    position: 'relative'
  }
})

const ForgotErrors = styled("span")({
  color: '#EA7D7D',
  marginBottom: '10px',
  lineHeight: "24px",
  display: 'inline-block',
  fontSize: '16px',
  "@media (max-width: 425px)": {
    fontSize: "12px",
    position: 'relative',
    lineHeight: "16px",
  }
})

const ForgotHeading = styled("p")({
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "32px",
  display: "flex",
  alignItems: "center",
  color: "#FAFAFA",
  paddingBottom: "24px",
  margin: '0px',
  fontFamily: 'Figtree',
  "@media (max-width: 425px)": {
    fontSize: '24px',
    lineHeight: '32px',
    paddingBottom: "5px",
  }
})

const ForgotText = styled(Typography)({
  fontWeight: 400,
  fontSize: "16px",
  // lineHeight: "24px",
  color: "#FAFAFA",
  opacity: 0.8,
  paddingBottom: "10px",
  marginTop: '10px',
  maxWidth: "492px",
  fontFamily: "Figtree",
  lineHeight: '24px',
  margin: "-13px auto 30px 0px",
  "@media (max-width: 425px)": {
    fontSize: '16px',
    lineHeight: '24px',
    display: 'block',
    width: '100%',
    wordWrap: 'break-word',
    paddingBottom: "5px",
  }
})

const SendButton = styled(Button)({
  background:
    "linear-gradient(0deg, rgba(250, 250, 250, 0.14), rgba(250, 250, 250, 0.14)), #1B122E",
  borderRadius: "24px 4px",
  width: "70%",
  height: "48px",
  color: "rgba(250, 250, 250, 0.4)",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  marginTop: "20px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#ECEF51",
    color: "#000"
  },
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: '16px'
  }
});

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={themes}>        
        <ParentGrids container spacing={3}>
          <DesignGrids item style={webStyle.designDiv} md={6}>
            <img style={webStyle.designImage} src={designImage} alt="" />
          </DesignGrids>
          <LoginGrids item style={webStyle.contentBox} md={6}>
            <LogoImage src={logo} alt="" />
            <ForgotHeading>Forgot password</ForgotHeading>
            <Box>
            <ForgotText>
              Don’t worry, we will send a verification e mail to reset your
              password.please enter your work Email ID.
            </ForgotText>
            </Box>
            <Box sx={webStyle.inputBoxes as CSSProperties}>
              <ForgotLabel>
                Work email ID
              </ForgotLabel>
                  <ForgotInput
                    autoComplete="off"
                    data-test-id="txtInputEmail"
                    fullWidth={true}
                    placeholder="Enter work email address"
                    value={this.state.email}
                    onChange={this.handleChangeemail}
                    style={{ border: this.state.emailError ? '1px solid #EA7D7D' : '1px solid #FAFAFA' }}
                  />
              <ForgotErrors
              >
                {this.state.emailError}
              </ForgotErrors>
            </Box>
            <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.popupMessage}
          autoHideDuration={2000}
          onClose={this.handleCloseSnackBar}
        >
        <this.Alert onClose={this.handleCloseSnackBar} severity="error">
          {this.state.responseData}
        </this.Alert>
        </Snackbar>
            <SendButton type="submit" onClick={() => this.sendVerificationEmail()} data-test-id="btnSubmit">
              Send Verification email{/*UI Engine::From Sketch*/}
            </SendButton>
          </LoginGrids>
        </ParentGrids>
      </ThemeProvider>
    );
  }
}
const webStyle = {
  backgroundColor: {
    background: "#251840",
    width:"100%",
    margin:"0px"
  },
  contentBox: {
    paddingLeft: "130px",
    width: "100%",
    paddingRight: "33px",
    "@media(max-width: 425px)": {
      paddingRight: "50px"
    }
  },
  designImage: {
    width: "400px",
    height: "1000px"
  },
  designDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(0deg, rgba(250, 250, 250, 0.05), rgba(250, 250, 250, 0.05)), #1B122E",
    borderRadius: "0px 0px 400px 0px",
    padding: "112px 0px"
  },
  inputBoxes: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "& .MuiInput-underline:after": {
      display: 'none !important',
    }
  },
  logo: {
    // paddingTop: '100px',
    paddingBottom: "40px",
    width: "200px",
    height: "51px",
    marginTop: "60px"
  },
  login: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center",
    color: "#FAFAFA",
    paddingBottom: "16px",
    margin: "0px",
    fontFamily: "Figtree"
  },
  forgotText: {
    color: "#67ADFF",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "24px",
    marginTop: "18px",
    maxWidth: "350px",
    textAlign: "end",
    fontFamily: "Figtree"
  }
};
// Customizable Area End
