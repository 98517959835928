import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";

export interface Props {
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showDropDown: boolean;
  dataCard: any;
  dropdownData: {
    label:string;
    value: number;
  }[];
  selectData: string;
  breadCrumData: {}[];
  token: string | null;
  account_id: string;
  contact_us: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSubCategoryById: string = "";
  postCreateCommits: string = "";
  getContactUs: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      showDropDown: false,
      dataCard: [],
      dropdownData: [],
      selectData: "",
      breadCrumData: [],
      token: "",
      account_id: "",
      contact_us: {}
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("user_token")) ||
      "";
    this.setState({ token: token });
    this.getSubCategoryData();
    this.handleContactUs();
    // Access the data from the location state
    // Use the data as needed
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getSubCategoryById) {
        this.callAfterApiCall(responseJson);
      }else if(apiRequestCallId === this.postCreateCommits){
        return responseJson;
      }else if(apiRequestCallId === this.getContactUs){
        const contactus = responseJson.filter((data: any) => {
          return data.title === "Contact Us";
        });
        this.setState({ contact_us: contactus[0].description });
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  handleBradCrumb = (response: {
    account:{data:{id:string}}
    solutions: {data: {id: string; type:string; attributes:{id:string; solution_description: string; ezeo_reward_points: string}}[];};
    subcategories: {
      data: {
        id: string;
        type: string;
        relationships: { solutions: { data: { id: string; type: string }[] } };
        attributes: { name: string; id: number };
      }[];
    };
  }) => {
    const solutions = response.solutions.data;
    const cardData = response.subcategories.data.map(
      (item: {
        id: string;
        type: string;
        relationships: { solutions: { data: { id: string; type: string }[] } };
        attributes: { name: string; id: number };
      }) => {
        const solutionSub = item.relationships.solutions.data.map(
          (item: { id: string; type: string }) =>
          solutions.find((i: {id:string}) => i.id === item.id)
          );
        const obj = {
          subCategory: item.attributes.name,
          id: item.id,
          solutions: solutionSub,
        };
        return obj;
      }
    );

    const options = [
      { label: "Most impactful", value: 1 },
      { label: "Easiest", value: 2 },
    ];
    this.setState({
      dataCard: cardData,
      dropdownData: options,
      breadCrumData: this.props.history.location.state.breadCrum,
      account_id:response.account.data.id
    });
  };

  callAfterApiCall = (responseJson: {
    solutions: {data: {id: string; type:string; attributes:{id:string; solution_description: string; ezeo_reward_points: string}}[];};
    subcategories: {
      data: {
        id: string;
        type: string;
        relationships: { solutions: { data: { id: string; type: string }[] } };
        attributes: { name: string; id: number };
      }[];
    };
    account:{data:{id:string}}
    errors?:boolean
  }) => {
    if (!responseJson.errors) {
      this.handleBradCrumb(responseJson);
      return responseJson;
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  getSubCategoryData = () => {
    const subCatId = this.props.history.location.state.breadCrum[1].id;
    const URL = `${configJSON.getSubCategoryAPIEndPoint}/${subCatId}/subcategories_and_solutions`;
    this.getSubCategoryById = sendAPIRequest(URL, {
      headers: { token: this.state.token, "Content-Type": "application/json" },
    });
  };

  // createCommit = (id: number) => {
  //   this.postCreateCommits = sendAPIRequest(configJSON.createCommit, {
  //     headers: { token: this.state.token, "Content-Type": "application/json"},
  //     method: "POST",
  //     body: {commit: { solution_id: id}}
  //   }); 
  // }

  handleOpenDropDown = () => {
    this.setState({ showDropDown: !this.state.showDropDown });
  };

  handleChange = (value: string) => {
    this.setState({ selectData: value });

    function customImpactSort(a:any, b:any) {
      const impactOrder :any= { high: 1, medium: 2, low: 3 };
      const impactA = impactOrder[value==='Most impactful'? a.attributes.impact_classification: value ==='Easiest' && a.attributes.ease_classification];
      const impactB = impactOrder[value==='Most impactful'? b.attributes.impact_classification: value ==='Easiest' && b.attributes.ease_classification];
      return impactA - impactB;
    }

    const sortedSolutions = this.state.dataCard?.map((ele:any)=>{
      ele.solutions.sort(customImpactSort)
      return ele;
    })
    this.setState({dataCard:sortedSolutions})
  };

  handleClick = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    clicked: string,
    title: string,
    data: {attributes:{id: number}}
  ) => {
    e.stopPropagation();
    if (window.innerWidth >= 600 && clicked === "clickOnDiv") {
      return null;
    } else {
      // this.createCommit(data.attributes.id)
      this.props.history.push({
        pathname: "Details",
        state: {
          breadCrum: [
            ...this.state.breadCrumData,
            { name: title, path: "Details", attributes: data.attributes },
          ],
          headerText: this.props.history.location.state.headerText,
          account_id: this.state.account_id,
        },
      });
    }
  };

  handleContactUs = () => {
    this.getContactUs = sendAPIRequest(configJSON.termsAndCondition);
  }

  // Customizable Area End
}
