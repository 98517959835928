/* eslint-disable react-native/no-inline-styles */
import React from "react";

// Customizable Area Start
import { Box, Typography, Card } from "@material-ui/core";
import AboutPageController, { Props } from "./AboutPageController.web";
import Navbar from "../../../components/src/Navbar.web";
import { styled } from "@material-ui/styles";
import CardContent from "@material-ui/core/CardContent";
// Customizable Area End

const AboutUsWrapper = styled("div")({
  backgroundColor: "#1B122E",
  height: "fit-content",
  "& .main-card": {
    alignItems: "center",
    bgcolor: "#1B122E",
    color: "#FCFCFC",
    margin: "0px 50px",
    "@media (max-width:600px)": {
      fontSize: "25px",
      margin: "0px 22px",
      padding: "0px 16px"
    }
  },
  "& .cardContent": {
    padding: "0px 20px",
    marginTop: "24px",
    borderRadius: "24px",
    backgroundColor: "#261E38",
    "@media (max-width:600px)": {
      padding:"0px 13px",
    }
  },
  "& .mobileContent": {
    backgroundColor: "#1B122E"
  },
  "& .about-us": {
    fontSize: "30px",
    fontWeight: 400,
    fontFamily: "Figtree, sans-serif",
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "25px"
    }
  },
  "& .description": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Figtree, sans-serif",
    color: "#FAFAFA"
  },
  "& .no-data-found": {
    fontFamily: "Figtree, sans-serif",
    marginTop: "21%",
    fontSize: "20px",
    fontWeight: 500,
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "14px",
      marginTop: "51%"
    }
  }
});

export default class AboutPage extends AboutPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <AboutUsWrapper>
        <Navbar
          // @ts-ignore
          history={this.props.history}
          params={"AboutEzeo"}
          headerText={"AboutEzeo"}
          contact_us={this.state.contact_us}
        />
            <Box
            bgcolor="#1B122E"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >       
          {this.state.dataCard?.description.length ? (
            <Box className="main-card">
            <Typography className="about-us">
                {this.state.dataCard.title}
            </Typography>
              <Card className="cardcontent">
              <CardContent>
                  <Box>
                      <Typography
                          className="description"
                          dangerouslySetInnerHTML={{
                          __html: this.state.dataCard.description
                          }}
                      />
                  </Box>
              </CardContent>
          </Card>
        </Box> 
          ) : (<Typography className="no-data-found">No Data available</Typography>)}
          </Box>       
      </AboutUsWrapper>
    );
  }
}
