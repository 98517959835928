// Customizable Area Start
import React, { CSSProperties } from "react";
import { designImage, logo, designImage1, designImage2 } from "./assets";
import {
  Grid,
  Box,
  Button,
  Input,
  FormControlLabel,
  Checkbox,
  Dialog,
  Typography,
  MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { InputEye } from "../../../components/src/InputEye";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import StopRoundedIcon from "@material-ui/icons/StopRounded";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const ParentGrid = styled(Grid)({
  background: "#1B122E",
  "@media(max-width: 425px)": {
    background: "rgb(37, 24, 64)"
  }
});
const DesignGrid = styled(Grid)({
  "@media(max-width: 767px)": {
    display: "none!important"
  }
});

const LoginGrid = styled(Grid)({
  "@media(max-width: 425px)": {
    paddingLeft: "35px!important",
    backgroundSize: "200px",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    paddingTop: "60px !important",
    background: `url(${designImage1})`,
    backgroundPositionY: "96px",
    width: "100px",
    objectFit: "cover"
  },
  "& .logo-center": {
    margin: "56px auto !important",
    display: "block"
  }
});

const InputTag = styled(Input)({
  borderRadius: "8px",
  width: "70%",
  height: "48px",
  padding: "0px 16px",
  marginBottom: "15px",
  background: "#1B122E",
  color: "#FAFAFA",
  fontSize: "16px",
  fontFamily: "Figtree",
  lineHeight: "16px",
  "& .MuiInputBase-input:-webkit-autofill" : {
    '-webkitTextFillColor': '#fff',
    background: '#2a0440',
    '-webkitBoxShadow': '0 0 0px 1000px #2a0440 inset',
  },
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: "14px",
    lineHeight: "10px",
    background: "#24183f",
    marginBottom: "10px"
  }
});

const LogoImage = styled("img")({
  paddingBottom: "88px",
  width: "200px",
  height: "51px",
  fontFamily: "Figtree",
  marginTop: "106px",
  "@media (max-width: 425px)": {
    width: "150px",
    height: "38px",
    objectFit: "cover",
    paddingBottom: "40px",
    marginTop: "56px"
  }
});

const SignupLabel = styled("label")({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FAFAFA",
  marginBottom: "10px",
  "@media (max-width: 425px)": {
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: "10px"
  }
});

const SignupErrors = styled("span")({
  color: "#EA7D7D",
  marginBottom: "10px",
  lineHeight: "16px",
  display: "inline-block",
  fontSize: "16px",
  width: "550px",
  "@media (max-width: 425px)": {
    fontSize: "12px",
    lineHeight: "16px",
    width: "fit-content"
  }
});

const SignupHeading = styled("p")({
  fontWeight: 700,
  fontSize: "32px",
  lineHeight: "40px",
  display: "flex",
  alignItems: "center",
  color: "#FAFAFA",
  paddingBottom: "24px",
  margin: "0px",
  fontFamily: "Figtree",
  "@media (max-width: 425px)": {
    fontSize: "24px"
  }
});

const SignUpButton = styled(Button)({
  background:
    "linear-gradient(0deg, rgba(250, 250, 250, 0.14), rgba(250, 250, 250, 0.14)), #1B122E",
  borderRadius: "24px 4px",
  width: "70%",
  height: "48px",
  color: "rgba(250, 250, 250, 0.4)",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  marginBottom: "34px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#ECEF51",
    color: "#000"
  },
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: "16px"
  }
});

const SelectBox = styled(Box)({
  width: "70%",
  "& .select-labels": {
    display: "block"
  },
  "& .form-controller": {
    width: "100%",
    "& .type-select": {
      color: "#FAFAFA",
      height: "48px",
      border: "1px solid #FAFAFA",
      borderRadius: "8px",
      padding: "0px 10px",
      "& .MuiSelect-icon": {
        fill: "#FAFAFA",
        right: "7px"
      }
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none"
      },
      "&:after": {
        display: "none"
      }
    }
  },
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: "14px",
    lineHeight: "10px",
    "& .form-controller": {
      background: "#24183f"
    }
  }
});

const ModelWrapper = styled(Dialog)({
  "& .preview-img": {
    width: "56px",
    height: "56px",
    borderRadius: "25px",
  },
  "& .user-type": {
    fontSize: "16px",
    fontWeight: 500,
  },
  "& .user-status": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#FC6D52"
  },
  "& .user-name": {
    margin: "10px 19px"
  },
  "& .model-title": {
    fontSize: "24px",
    fontWeight: 700,
    padding: "25px",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
    }
  },
  "& .setting-box": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .report-modal": {
    padding:"8px 10px 15px"
  },
  "@media (max-width: 425px)": {
    width: "100%",
    fontSize: "14px",
    lineHeight: "10px",
  }
});

const UserSelect = styled(Box)({
  "& .user-type-wrapper": {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    backgroundColor:"#3a324b",
    borderRadius:"24px",
    padding:"2px 10px",
    margin:"16px 64px"
  },
  "& .mobile-screen":{
    color:"#FAFAFA",
    margin:"16px 0px !important",
  },
  "& .mobile-screen-status":{
    color:"#FC6D52"
  }

})

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

// Customizable Area End
export default class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  userTypeSelect = (mobile: boolean) => (
    this.state.userData.map((data: {name: string, type: string, value: string, img: any}) => {
      return(
        <UserSelect>
        <Box
          className={!mobile ? "user-type-wrapper" : "user-type-wrapper mobile-screen"}
          key={data.name}        
          data-test-id ="user-type"
          onClick={() => this.handleUserType(data.value)}
        >
          <Box display="flex" alignItems="center" gridGap="10px" padding="10px">
            <img
              src={data.img}
              alt="preview"
              className="preview-img"
            />
            <Box className="user-name">
              <Typography variant="body2" className="user-type">
                {data.name}
              </Typography>
              <Typography variant="body2" className={!mobile ? "user-status" : "user-status mobile-screen-status"}>
              {data.type}
              </Typography>
            </Box>
          </Box>
        </Box>
        </UserSelect>
      )
    })
  )

  renderUsertypeModel = () => {
    return (
      <ModelWrapper
        open={this.state.dialog&&!this.state.mobileUsertype}
        onClose={this.handleCloseModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#2d253e",
            borderRadius: "24px",
            padding: "20px",
            color: "#fff",
            cursor: "pointer"
          }
        }}
      >
       <Box marginBottom="15px">
          <Typography variant="body1" className="model-title">
            {this.state.firstName}, how you describe yourself ?
          </Typography>
          {this.userTypeSelect(false)}
        </Box>
      </ModelWrapper>
    );
  };

  errorBorderColor = (error: boolean) => {
    return(error? "1px solid #EA7D7D":"1px solid #FAFAFA")
  }

  mobileScreen = (mobileTypeSelect:boolean) => {
    return(mobileTypeSelect ? {...webStyle.contentBox, backgroundImage:`url(${designImage2})`, backgroundPositionY:"70%"} : webStyle.contentBox)
  }

  // Customizable Area End

  render() {
    const MenuProps = {
      PaperProps: {
        style: {
          backgroundColor: "#3A324B",
          color: "#FAFAFA",
          padding: "6px 17px"
        }
      }
    };

    const mobileTypeSelect = this.state.dialog&&this.state.mobileUsertype;

    
    return (
      // Customizable Area Start
      <div className="">
        {this.renderUsertypeModel()}
        {!this.state.termsCondition && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            onClose={this.handleCloseSnackBarForTC}
            open={this.state.popupMessage}
            autoHideDuration={2000}
          >
            <this.Alert
              onClose={this.handleCloseSnackBarForTC}
              severity="error"
            >
              Please agree to terms and conditions
            </this.Alert>
          </Snackbar>
        )}
        {this.state.apiSuccess && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            onClose={this.handleCloseSnackBar}
            open={this.state.popupMessage}
            autoHideDuration={2000}
          >
            <this.Alert
              severity={this.state.errStatus ? "error" : "success"}
              onClose={this.handleCloseSnackBar}
            >
              {this.state.responseData}
            </this.Alert>
          </Snackbar>
        )}

        <ThemeProvider theme={theme}>
          <ParentGrid container spacing={3}>
            <DesignGrid item style={webStyle.designDiv} md={6}>
              <img style={webStyle.designImage} src={designImage} alt="" />
            </DesignGrid>
            <LoginGrid item style={this.mobileScreen(mobileTypeSelect)} md={6}>
              <LogoImage src={logo} alt="" className={mobileTypeSelect?"logo-center":""}/>
              <SignupHeading>{mobileTypeSelect?`${this.state.firstName}, how you describe yourself ?`:"Sign up"}</SignupHeading>
              {mobileTypeSelect?
                <Box marginBottom="15px">
                  {this.userTypeSelect(true)}
                </Box>
              :<Box>
                <Box sx={webStyle.inputBoxx as CSSProperties}>
                  <SignupLabel htmlFor="emailinput">First name</SignupLabel>
                  <InputTag
                    value={this.state.firstName}
                    style={{
                      border: this.errorBorderColor(!!this.state.firstNameError)
                    }}
                    name="firstName"
                    autoComplete="off"
                    placeholder="Enter First Name"
                    data-test-id="txtFirstName"
                    onChange={this.handleChangefirstName}
                  />
                  <SignupErrors>{this.state.firstNameError}</SignupErrors>
                </Box>

                <Box style={webStyle.inputBoxx as CSSProperties}>
                  <SignupLabel htmlFor="emailinput">Last name</SignupLabel>
                  <InputTag
                    type="text"
                    name="lastName"
                    autoComplete="off"
                    placeholder="Enter Last Name"
                    data-test-id="txtLastName"
                    value={this.state.lastName}
                    onChange={this.handleChangelastName}
                    style={{
                      border: this.errorBorderColor(!!this.state.lastNameError)
                    }}
                    id="emailinput"
                  />
                  <SignupErrors>{this.state.lastNameError}</SignupErrors>
                </Box>

                <Box sx={webStyle.inputBoxx as CSSProperties}>
                  <SignupLabel htmlFor="emailinput">Work email ID</SignupLabel>
                  <InputTag
                    data-test-id="txtInputEmail"
                    type="email"
                    name="email"
                    id="emailinput"
                    autoComplete="off"
                    style={{
                      border: this.errorBorderColor(!!this.state.emailError)
                    }}
                    placeholder="Enter work email"
                    value={this.state.email}
                    onChange={this.handleChangeemail}
                  />
                  <SignupErrors>{this.state.emailError}</SignupErrors>
                </Box>

                <Box sx={webStyle.inputBoxx as CSSProperties}>
                  <SignupLabel htmlFor="passwordinput">Password</SignupLabel>
                  <InputTag
                    data-test-id="txtInputPassword"
                    type={this.changePasswordType()}
                    name="password"
                    id="passwordinput"
                    autoComplete="off"
                    placeholder="Enter password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    style={{
                      border: this.errorBorderColor(!!this.state.passwordError)
                    }}
                    endAdornment={
                      <InputEye
                        dataid="btnShowHidePassword"
                        enablePasswordField={this.state.enablePasswordField}
                        handleShowPassword={this.enablePasswordShow}
                      />
                    }
                  />
                  <SignupErrors>{this.state.passwordError}</SignupErrors>
                </Box>

                <Box sx={webStyle.inputBoxx as CSSProperties}>
                  <SignupLabel htmlFor="passwordinput">
                    Confirm password
                  </SignupLabel>
                  <InputTag
                    data-test-id="txtInputConfirmPassword"
                    type={this.changeConfirmPasswordType()}
                    name="reTypePassword"
                    id="passwordinput"
                    autoComplete="off"
                    placeholder="Enter password"
                    value={this.state.reTypePassword}
                    onChange={this.handleChangeRepassword}
                    style={{
                      border: this.errorBorderColor(!!this.state.reTypePasswordError)
                    }}
                    endAdornment={
                      <InputEye
                        dataid="btnConfirmShowHidePassword"
                        enablePasswordField={this.state.enableReTypePasswordField}
                        handleShowPassword={this.enableConfirmPasswordShow}
                      />
                    }
                  />

                  <SignupErrors>{this.state.reTypePasswordError}</SignupErrors>
                </Box>

                <SelectBox>
                  <SignupLabel htmlFor="passwordinput" className="select-labels">
                    Select Type
                  </SignupLabel>
                  <FormControl className="form-controller">
                    <Select
                      data-test-id="select-data"
                      value={this.state.type}
                      onChange={this.handleTypeSelect}
                      label="Age"
                      className="type-select"
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple"
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={"employer"}>Employer</MenuItem>
                      <MenuItem value={"employee"}>
                        Employee
                      </MenuItem>
                    </Select>
                  </FormControl>
                </SelectBox>

                <Box sx={webStyle.accountDivs}>
                  <FormControlLabel
                    data-test-id="btnLegalTermsAndCondition"
                    control={
                      <Checkbox
                        data-test-id="checkbox-element"
                        checked={this.state.termsCondition}
                        onChange={this.handleCheckboxChange}
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<StopRoundedIcon fontSize="medium" />}
                        name="checkedH"
                        style={webStyle.checkIcon}
                        indeterminate={false}
                      />
                    }
                    label=""
                  />
                  <Box sx={webStyle.readPolicy}> I've read and agreed to</Box>
                  <Box
                    data-test-id={"btnLegalPrivacyPolicy"}
                    sx={webStyle.forgotText as CSSProperties}
                  >
                    <Typography onClick={this.handleNavigateTermsAndCondition}>Terms and conditions{" "}</Typography>
                  </Box>
                </Box>
                <SignUpButton
                  data-test-id="btnSignUp"
                  onClick={() => this.createAccount(window.innerWidth<600)}
                >
                  Sign up
                </SignUpButton>
              </Box>}
            </LoginGrid>
          </ParentGrid>
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  contentBox: {
    paddingLeft: "130px",
    width: "100%",
    minHeight: "105vh",
    paddingRight: "33px",
    "@media(max-width: 425px)": {
      paddingRight: "50px"
    }
  },
  designImage: {
    width: "400px",
    height: "800px"
  },
  designDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(0deg, rgba(250, 250, 250, 0.05), rgba(250, 250, 250, 0.05)), #1B122E",
    borderRadius: "0px 0px 400px 0px",
    padding: "112px 0px",
    fontFamily: "Figtree"
  },
  inputBoxx: {
    display: "flex",
    flexDirection: "column",
    gap: "-5px",
    "& .MuiInput-underline:after": {
      display: "none !important"
    }
  },
  accountDivs: {
    fontWeight: 300,
    fontSize: "14px",
    display: "flex",
    lineHeight: "14px",
    color: "#FAFAFA",
    marginTop: "5px",
    alignItems: "center",
    marginBottom: "15px",
    "@media(max-width: 425px)": {
      fontSize: "13px",
      width: "100%",
      marginTop: "5px",
      marginBottom: "5px"
    }
  },
  firstName: { border: "1px solid #FAFAFA" },
  inputTag: {
    // border: "1px solid #FAFAFA",
    borderRadius: "8px",
    width: "350px",
    height: "48px",
    padding: "0px 16px",
    background:
      "linear-gradient(0deg, rgba(250, 250, 250, 0.05), rgba(250, 250, 250, 0.05)), #1B122E",
    marginBottom: "24px",
    color: "#FAFAFA",
    fontSize: "16px",
    fontFamily: "Figtree"
  },
  forgotText: {
    color: "#67ADFF",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "24px",
    maxWidth: "400px",
    alignItem: "center",
    marginTop: "26px",
    marginLeft: "5px",
    fontFamily: "Figtree",
    textDecoration: "underline",
    cursor:"pointer",
    "@media(max-width: 425px)": {
      fontSize: "14px",
      
    width:"56%",
    }
  },
  readPolicy: {
    fontFamily: "Figtree",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "left",
    top: "834px",
    marginLeft: "10px",
    "@media(max-width: 425px)": {
      fontSize: "14px",
      marginLeft: "6px",
    width:"51%",
    }
  },
  checkIcon: {
    color: "rgb(252, 109, 82)",
    border: "2px solid #FC6D52",
    borderRadius: "5px",
    width: "0px",
    height: "0px",
    margin: "0px -18px 0px 10px",
    padding: "8px"
  }
};

// Customizable Area End
