import React from "react";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/styles";

import {
  logo,
  userlogo,
  walkthrough_1,
  walkthrough_2,
  walkthrough_3,
  walkthrough_4,
  next,
  previous,
  bg_img, userlogo2, userlogo3
} from "../../../components/src/assets";

const WalkthroughStyled = styled(Box)({
  backgroundColor: "#1B122E",
  backgroundImage: "url(" + bg_img + ")",
  backgroundPosition: "center 160px",
  backgroundRepeat: "no-repeat",
  color: "#FCFCFC",
  padding: "72px",
  height: "100%",
  fontFamily: "Figtree",
  "& *": {
    boxSizing: "border-box"
  },
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    "& .navigationLogo": {
      maxWidth: "195px"
    },
    "& .profile": {
      borderRadius: "50%"
    }
  },
  "& .mainBox": {
    background:
      "var(--ezeo-primary-dark-surface-0, linear-gradient(0deg, rgba(250, 250, 250, 0.05) 0%, rgba(250, 250, 250, 0.05) 100%), #1B122E)",
    borderRadius: "24px",
    maxWidth: "500px",
    margin: "auto",
    padding: "32px 24px",
    "& h3": {
      margin: "0"
    },
    "& .lp-slideshow": {
      width: "100%",
      "& .container": {
        position: "relative",
        margin: "auto",
        width: "100%",
        maxHeight: "475px",
        "& .slide": {
          width: "100%",
          height: "auto",
          position: "absolute",
          margin: "auto",
          opacity: "0",
          transition: "opacity 0.6s ease",
          "-webkit-transition": "opacity 0.6s ease",
          "-moz-transition": "opacity 0.6s ease",
          "-o-transition": "opacity 0.6s ease",
          "&.active": {
            opacity: "1"
          },
          "& .image": {
            textAlign: "center",
            "& img": {
              width: "100%"
            }
          },
          "& .caption-text": {
            color: "#FC6D52",
            textAlign: "center",
            fontSize: "36px",
            fontWeight: "700",
            lineHeight: "44px"
          }
        }
      },
      "& .navigition": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "30px",
        position: "relative",
        zIndex: 999,
        "& .prev , .next": {
          display: "flex",
          alignItems: "center",
          gap: "5px",
          fontWeight: 700,
          cursor: "pointer",
          background: "none",
          border: "none",
          outline: "none",
          color: "#FCFCFC",
          "&.hidden": {
            opacity: "0",
            visibility: "hidden"
          }
        }
      },
      "& .startBtn": {
        marginTop: "30px",
        padding: "0 32px",
        border: "none",
        width: "100%",
        height: "48px",
        borderRadius: "24px 4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#1B122E",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        background: "#ECEF51",
        cursor: "pointer",
        boxShadow:
          "0px 13px 50px rgba(0, 0, 0, 0.09), 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 4px 32px rgba(0, 0, 0, 0.06)",
        "&:disabled": {
          color: "#94909D",
          background:
            "linear-gradient(0deg, rgba(250, 250, 250, 0.14), rgba(250, 250, 250, 0.14)), #1B122E;"
        }
      }
    }
  },
  "@media only screen and (max-width: 900px)": {
    padding: "72px 20px",
    backgroundImage: "none",
    height: "100%",
    "& .header": {
      justifyContent: "center",
      "& .navigationLogo": {
        width: "150px"
      },
      "& .profile": {
        display: "none"
      }
    },
    "& .mainBox": {
      "& h3": {
        fontSize: "24px !important",
        lineHeight: "32px !important"
      }
    }
  }
});

const collection = [
  { src: walkthrough_1, caption: "Choose your areas" },
  { src: walkthrough_2, caption: "Select from our recommendations" },
  { src: walkthrough_3, caption: "Take Action" },
  { src: walkthrough_4, caption: "Earn Rewards" }
];
type MyState = { slideIndex: number };
type MyProps = { history: {push: (path: string, state?: any) => void;}; location: {
  pathname: string;
  search: string;
  hash: string;
  state: string; 
  
} };
export default class Walkthrough extends React.Component<MyProps, MyState> {
  containerRef: React.RefObject<any>;
  SiderRef: React.RefObject<any>;
  profielType:any
  constructor(props: MyProps) {
    super(props);
    this.state = {
      slideIndex: 0
    };
    this.SiderRef = React.createRef();
    this.containerRef = React.createRef();
    this.profielType=localStorage.getItem("profielType")
  }

  getNewSlideIndex = (step: number) => {
    const numberSlide = collection.length;

    let newSlideIndex = this.state.slideIndex + step;

    if (newSlideIndex >= numberSlide) {
      newSlideIndex = this.state.slideIndex;
    } else if (newSlideIndex < 0) {
      newSlideIndex = numberSlide - 1;
    }

    return newSlideIndex;
  };
  backward = () => {
    this.setState({ slideIndex: this.getNewSlideIndex(-1) });
  };

  forward = () => {
    this.setState({ slideIndex: this.getNewSlideIndex(1) });
  };

  resize = () => {
    this.containerRef.current.style.height = `${
      this.SiderRef.current.clientHeight
    }px`;
  };

  componentDidMount() {
    global.localStorage.getItem("walkthrough_token");
    window.addEventListener("resize", this.resize);
    if (this.containerRef.current) {
      this.containerRef.current.style.height = `${this.containerRef.current
        .clientWidth + 26}px`;
    }
  }

  handleClick = () => {
    this.props.history.push("/EmployeeHome");
    global.localStorage.setItem("user_token", this.props.location.state);
    global.localStorage.setItem("Logout", "false");
    global.localStorage.removeItem("walkthrough_token");
  };
  //  profielType= localStorage.getItem("profielType")

   setImage =() =>{ 
        if(this.profielType === 'Eco_Unaware'){
            return `${userlogo}`
           } else if(this.profielType === 'Eco_Conscious')
             {return `${userlogo2}`}
             else  if(this.profielType === 'Eco_Active'){ 
               return `${userlogo3}` }
                }


  
  render() {

    return (
      <WalkthroughStyled>
        <div className="header">
          <img src={logo} className="navigationLogo" width={200} />
          {/* <img src={userlogo} className="profile" width={50} /> */}
          <img src={this.setImage()} className='profile' />
         
        </div>
        <div className="mainBox">
          {/* <h3>Earn as you go !</h3> */}
          <div className="lp-slideshow">
            <div className="container" ref={this.containerRef}>
              {collection.map((image, index) => {
                return (
                  <div
                    key={index}
                    ref={this.SiderRef}
                    className={`slide ${
                      this.state.slideIndex === index ? "active" : ""
                    }`}
                  >
                    <h3 className="caption-text" data-test-id="caption">
                      {image.caption}
                    </h3>
                    <div className="image">
                      <img src={image.src} alt={image.caption} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="navigition">
              <button
                className={
                  "prev " + (this.state.slideIndex === 0 ? "hidden" : "")
                }
                disabled={this.state.slideIndex === 0 ? true : false}
                data-test-id="previous"
                onClick={this.backward}
              >
                <img src={previous} /> previous
              </button>
              <div className="number-text">
                {`${this.state.slideIndex + 1} of ${collection.length}`}
              </div>
              <button
                className={
                  "next " +
                  (this.state.slideIndex === collection.length - 1
                    ? "hidden"
                    : "")
                }
                disabled={
                  this.state.slideIndex === collection.length - 1 ? true : false
                }
                data-test-id="next"
                onClick={this.forward}
              >
                next <img src={next} />
              </button>
            </div>

            <button
              className="startBtn"
              data-test-id="lets-go"
              disabled={
                this.state.slideIndex === collection.length - 1 ? false : true
              }
              onClick={this.handleClick}
            >
              <span className="text">Lets go Zero !</span>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 11.0863C6.00969 11.1542 10.8724 15.9978 10.9713 22H10.9743C11.0735 15.9797 15.9653 11.125 22 11.0859V11.0854C15.9081 11.0459 10.9809 6.0993 10.9728 0C10.9647 6.08113 6.0668 11.0164 0 11.0849V11.0863Z" />
              </svg>
            </button>
          </div>
        </div>
      </WalkthroughStyled>
    );
  }
}
