Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.point_earned = "Point Earned";
exports.RewardsValue = "Rewards Value";
exports.currency_sign = "$";
exports.Redeem = "Redeem";
exports.Rewards = "Rewards";
exports.CommitedPoints = "Total Points Committed";
exports.commitments = "Commitments";
exports.Report_Progress = "Report Progress";
exports.View_Solutions = "View Solutions";
exports.getCurrentPoint = "bx_block_reward/rewards/current_point_redeem";
exports.getEmployeePoint = "bx_block_commit/commits/employee_points_api";
exports.showRedeemPoint = "bx_block_reward/rewards/show_point_redeems";
exports.about = "bx_block_settings/settings/terms_and_condition";
exports.solutionCommitments = "bx_block_commit/commits/solution_commitments_api";
exports.profieType = "account_block/accounts/user_profile_type";
exports.getEmployerData = "bx_block_settings/settings/get_employer_data";
exports.updateEmployerData = "bx_block_settings/settings/update_employer_data";
exports.emissionReductionCategory = "bx_block_solutions/solutions/emission_reduction_category";
exports.avgIndividualEmp = "bx_block_solutions/solutions/avg_individual_employee_impact";
exports.termsAndCondition = "bx_block_settings/settings/terms_and_condition";

exports.earning = {
  id: 1,
  solution_title:
    "Reduce travel distance by working from home and not using your car",
  solution_description:
    "Why travel to work when you can work from your couch in your PJs? Working from home reduces your carbon footprint by eliminating the need for transportation, and it's a great excuse to stay in your cozy clothes all day.",
  ezeo_reward_points: "500",
  solution_horizon: "act",
  earnings: [
    {
      id: 2,
      title: "Ezeo vouchers",
      value: "$100",
      solution_id: 1,
      created_at: "2023-07-05T05:34:21.870Z",
      updated_at: "2023-07-06T15:06:27.925Z",
    },
    {
      id: 3,
      title: "Carbon Impact ",
      value: "15 kg CO2e",
      solution_id: 1,
      created_at: "2023-07-05T05:34:21.874Z",
      updated_at: "2023-07-05T12:13:51.856Z",
    },
    {
      id: 4,
      title: "Provider discount",
      value: "30%",
      solution_id: 1,
      created_at: "2023-07-05T12:13:51.859Z",
      updated_at: "2023-07-05T12:13:51.859Z",
    },
    {
      id: 5,
      title: "Government grant ",
      value: "$800",
      solution_id: 1,
      created_at: "2023-07-05T12:13:51.862Z",
      updated_at: "2023-07-05T12:13:51.862Z",
    },
    {
      id: 6,
      title: "Insurance reduction ",
      value: "20%",
      solution_id: 1,
      created_at: "2023-07-05T12:13:51.865Z",
      updated_at: "2023-07-05T12:13:51.865Z",
    },
  ],
};
// Customizable Area End