import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('user_token') !== null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/EmailAccountLoginBlock', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticatedForWalkThrough = localStorage.getItem('walkthrough_token') !== null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedForWalkThrough ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/EmailAccountRegistration', state: { from: props.location } }} />
        )
      }
    />
  );
};


