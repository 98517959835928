import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  Hidden,
  Dialog,
  TextField,
} from "@material-ui/core";
import DetailsController, { Props, configJSON } from "./DetailsController.web";
import Navbar from "../../../components/src/Navbar.web";
import BreadCrumb from "../../../components/src/BreadCrumb.web";
import { styled } from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";
import {
  CloseIcon,
  cubeImage,
  star,
  UploadPin,
  sucesslogo,
  formBackground,
} from "./assets";
import { Formik, Field, Form } from "formik";
import LinearProgress from '@material-ui/core/LinearProgress';
// Customizable Area End

//Customizable Area Start
const Wrapper = styled(Box)({
  backgroundColor: "#1B122E",
  color: "rgb(250 250 250 )",
  "& .cube-img": {
    width: 23,
    marginLeft:"10px",
  },
  "& .heading-text": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    margin: "42px 0 26px",
    fontFamily: "Figtree, sans-serif",
    "@media (max-width:600px)": {
      margin: "42px 0px 18px",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
  },
  "& .first-list": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  "& .second-list": {
    fontWeight: 700
  },
  "& .submit-btn": {
    width: "100%",
    backgroundColor: "#ECEF51",
    borderRadius: "17px 2px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "16px",
    color: "#1B122E",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
  },
  "& .disable-submit-btn": {
    backgroundColor:"#ecef518a !important"
  },
  "& .description": {
    fontFamily: "Figtree, sans-serif",
    color: "rgb(250 250 250 / 60%)",
    marginTop: "33px",
    "@media (max-width:600px)": {
      fontSize: "12px",
      lineHeight: "20px",
      fontWeight: 400,
    },
  },
  "& .star-logo": {
    width: "18px",
    position: "absolute",
    top: "-6px",
  },
  "& .read-more": {
    color: "#67ADFF",
    "@media (max-width:600px)": {
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 500,
    },
  },
  "& .earn-title": {
    position: "relative",
    marginTop: "49px",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    "@media (max-width:600px)": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
    },
  },
  "& .font-family": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    marginLeft:"14px",
    textTransform:"capitalize"
  },
});

const AdditionalQueWrapper = styled(Box)({
  backgroundColor: "#1B122E",
  "& .main-container": {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "90%",
    margin: "0 auto",
    backgroundImage: `url(${formBackground})`,
    backgroundPositionY: "center",
  },
  "& .form-title": {
    "@media (max-width:600px)": {
      marginTop: "25px",
    },
  },
  "& .form-heading": {
    fontFamily: "Figtree, sans-serif",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 700,
    color: "#FC6D52",
    lineHeight: "32px",
    paddingBottom: "16px",
    "@media (max-width:600px)": {
      display: "none",
    },
  },
  "& .form-desc": {
    fontSize: "18px",
    fontWeight: 400,
    color: "#FAFAFA",
    lineHeight: "26px",
    textAlign: "left",
    fontFamily: "Figtree, sans-serif",
    marginBottom: "32px",
    "@media (max-width:600px)": {
      fontSize: "16px",
      marginBottom: "6px",
    },
  },
  "& .main-box": {
    width: "27%",
    margin: "0 auto",
    "@media (max-width:1080px)": {
      width: "45%",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  "& .que-form": {
    backgroundColor: "#261e38",
    color: "#a7a4af",
    padding: "0px 24px",
    borderRadius: "24px",
    "@media (max-width:600px)": {
      padding: "0px",
      backgroundColor: "#1B122E",
    },
  },
  "& .input-box": {
    width: "100%",
    height: "48px",
    backgroundColor: "transparent",
    border: "0.7px solid #a7a4af",
    borderRadius: "8px",
    fontSize: "18px",
    padding: "0 12px",
    color: "#a7a4af",
  },
  "& .form-box": {
    padding: "9px 0px",
    paddingTop: "18px",
    "@media (max-width:600px)": {
      paddingTop: "20px",
    },
  },
  "& .input-label": {
    paddingBottom: "10px",
    fontFamily: "Figtree, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: "14px",
    },
  },
  "& .submit-btn": {
    width: "100%",
    backgroundColor: "yellow",
    borderRadius: "17px 2px",
    padding: "8px",
    textTransform: "none",
    letterSpacing: "1px",
    fontSize: "16px",
    color: "#1B122E",
    fontWeight: 700,
    fontFamily: "Figtree, sans-serif",
    margin: "10px 0px 24px 0px",
  },
  "& .error-message": {
    marginTop: "5px",
    fontSize: "13px",
    letterSpacing: "0.4px",
  },
});

const ModelWrapper = styled(Dialog)({
  "& .submit-btn": {
    borderRadius: "24px",
    padding: "12px 32px",
    fontSize: "16px",
    lineHeight: "24px",
    width: " 140px",
    height: "48px",
    background: "#3a324b",
    textTransform: "none",
    color: "#94909D",
    marginLeft: "16px",
  },
  "& .submit-action": {
    borderRadius: "24px",
    padding: "12px 32px",
    fontSize: "16px",
    lineHeight: "24px",
    width: " 140px",
    height: "48px",
    background: "#ECEF51",
    textTransform: "none",
    marginLeft: "16px",
    fontFamily: "Figtree, sans-serif",
  },
  "& .cancle-action": {
    borderRadius: "24px",
    padding: "12px 32px",
    fontSize: "16px",
    lineHeight: "24px",
    width: " 140px",
    height: "48px",
    color: "#FAFAFA",
    border: "1px solid #FAFAFA",
    textTransform: "none",
    fontFamily: "Figtree, sans-serif",
  },
  "& .model-title": {
    fontSize: "18px",
    fontWeight: 700,
    paddingBottom: "5px",
    fontFamily: "Figtree, sans-serif",
    "@media (max-width:600px)": {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .model-description": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Figtree, sans-serif",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 400,
      margin: "10px 0px",
      fontFamily: "Figtree, sans-serif",
    },
  },
  "& .preview-img": {
    width: "56px",
    height: "56px",
    borderRadius: "8px",
  },
  "& .action-btn": {
    marginTop: "15px",
  },
  "& .logo-content": {
    width: "fit-content",
    margin: "0px auto",
    textAlign: "center",
  },
  "& .sucess-content": {
    paddingTop: "14px",
    color: "#FC6D52",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Figtree, sans-serif",
  },
  "& .text-content": {
    margin: "10px 6px",
    "@media (max-width:600px)": {
      margin: "10px 0px",
    },
  },
  "& .text-content-sucess": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Figtree, sans-serif",
    color: "#FAFAFA",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 400,
      margin: "10px 0px",
    },
  },
  "& .text-content-sucess-commit": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Figtree, sans-serif",
    textAlign: "center",
    color: "#FAFAFA",
    padding: "8px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      fontWeight: 400,
      margin: "10px 0px",
    },
  },
  "& .uploaded-file-name": {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  "& .MuiLinearProgress-colorPrimary":{
    backgroundColor:"gray",
  },
  "& .MuiLinearProgress-barColorPrimary":{
    backgroundColor:"rgb(58, 50, 75)"
  }
});
const InputWrapper = styled(Box)({
  margin: "16px auto",
  border: "2px dashed #FAFAFA",
  background: "#3a324b",
  boxSizing: "border-box",
  minWidth: "215px",
  borderRadius: "12px",
  width: "100%",
  "& .browse-file-btn": {
    cursor: "pointer",
    padding: "7%",
    fontFamily: "Figtree, sans-serif",
  },
  "& .upload-logo": {
    width: "10px",
    marginRight: "5px",
  },
  "& .input-hide ": {
    display: "none",
  },
  "& .image-remove": {
    paddingRight: "10px",
  },
  "& .fileupload-text": {
    fontSize: "16px",
    fontFamily: "Figtree, sans-serif",
  },
});

const ReportModelBox = styled(Box)({
  backgroundColor: "#1B122E",
  height: "auto",
  "@media (min-height: 900px)": {
    height: "100vh",
  },
  "& .bread-crumb": {
    display: "flex",
    width: "90%",
    margin: "50px auto",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  "& .bread-crumb-sec": {
    display: "flex",
    width: "90%",
    margin: "50px auto",
  },
});
//Customizable Area End

export default class Details extends DetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderReportModel = () => {
    return (
      <ModelWrapper
        open={this.state.openReportModel}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#2d253e",
            borderRadius: "24px",
            padding: "20px",
            color: "#fff",
          },
        }}
      >
        {this.state.openSucessModal ? (
          <Box>
            <Box className="logo-content">
              <img
                src={sucesslogo}
                alt="sucesslogo-img"
                className="sucesslogo-img"
              />
              <Typography className="sucess-content">
                {configJSON.sucess}
              </Typography>
            </Box>
            <Box className="text-content">
              <Typography className="text-content-sucess">
                {configJSON.sucess_message}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className="report-modal">
            <Box marginBottom="15px">
              <Typography variant="body1" className="model-title">
                {configJSON.report_progress}
              </Typography>
              <Typography variant="body2" className="model-description">
                {configJSON.upload_message}
              </Typography>
            </Box>
            {this.state.files.length <= 0 ? (
              <InputWrapper
                component="div"
                className="record-box"
                data-test-id="droper-id"
                onDrop={(e) => {
                  this.handleOnImportFile(e.dataTransfer.files);
                  e.preventDefault();
                }}
                onDragOver={(e) => e.preventDefault()}
              >
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  data-test-id="click-id"
                  className="browse-file-btn"
                  onClick={this.clickInput}
                >
                  <TextField
                    type="file"
                    id={`fileInput`}
                    data-test-id="fileInput"
                    className="input-hide"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      this.handleOnImportFile(e.target.files!);
                      e.preventDefault();
                    }}
                    inputProps={{
                      accept:
                        ".jpg, .jpeg, .png, .xlsx, .pdf, .docx, .mp4, .mkv",
                      multiple: true,
                    }}
                  />
                  <img
                    src={UploadPin}
                    alt="UploadPin"
                    className="upload-logo"
                  />
                  <Typography variant="body2" className="fileupload-text">
                    {configJSON.upload_file}
                  </Typography>
                </Box>
              </InputWrapper>
            ) : (
              this.state.files.map((item) => (
                <Box
                  key={item?.name}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgcolor="#3a324b"
                  borderRadius="8px"
                  padding="11px 10px"
                  margin="10px 0px"
                >
                  <Box display="flex" alignItems="center" gridGap="10px">
                    <img
                      src={item?.file && window.URL.createObjectURL(item.file)}
                      alt="preview"
                      className="preview-img"
                    />
                    <Typography variant="body2" className="uploaded-file-name">
                      {item?.name}
                    </Typography>
                  </Box>
                  <img
                    src={CloseIcon}
                    alt="close"
                    data-test-id="remove-img"
                    onClick={() => this.removeFile(item?.name)}
                    className="image-remove"
                  />
                </Box>
              ))
            )}
            {this.state.errorMsg ? (
              <Typography color="error" style={{ fontSize: "12px" }}>
                Maximum file size is 500MB
              </Typography>
            ) : (
              ""
            )}
            {this.state.loaderData ? <LinearProgress/> : ""}
            <Typography style={{marginTop:"5px"}}>{this.state.uploadMessage}</Typography>
            <DialogActions className="action-btn">
              <Button
                className="cancle-action"
                variant="outlined"
                onClick={this.handleClose}
              >
                Cancel
              </Button>
              <Button
                className={
                  this.state.files.length <= 0 || this.state.submitBtn ? "submit-btn" : "submit-action" 
                }
                data-test-id="submit-files"
                onClick={this.handleSubmit}
                disabled={this.state.files.length <= 0 || this.state.submitBtn ? true : false }
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Box>
        )}
      </ModelWrapper>
    );
  };

  handleError = (error: string | undefined, touch: boolean | undefined) => {
    if (error && touch) {
      return (
        <Typography color="error" className="error-message">
          Required Field*
        </Typography>
      );
    }
  };

  handleSucessModel = () => {
    return(
      <ModelWrapper
        open={this.state.commitSucessMsg}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#2d253e",
            borderRadius: "24px",
            padding: "20px",
            color: "#fff",
          },
        }}
      >
        {this.state.commitSucessMsg ? (
          <Box>
            <Box className="logo-content">
              <img
                src={sucesslogo}
                alt="sucesslogo-img"
                className="sucesslogo-img"
              />
            </Box>
            <Box className="text-content">
              <Typography className="text-content-sucess-commit">
                Thanks for your commitment to reducing your carbon footprint! Please update your progress through the home dashboard.
              </Typography>
            </Box>
          </Box>
        ) : ""}
      </ModelWrapper>
    )
  }

  rewardsQuestion = () => {
    return (
      <AdditionalQueWrapper>
        <Box className="main-container">
          <Box className="main-box">
            <Box className="form-title">
              <Typography className="form-heading">
                {configJSON.additional_que}
              </Typography>
              <Typography className="form-desc">
                {configJSON.form_desc}
              </Typography>
            </Box>
            <Formik
              data-test-id="formik-form"
              initialValues={this.state.formData}
              validationSchema={this.state.schema}
              onSubmit={(value: {[key: string]: string;}) => {
                const answer = Object.keys(value).map((item: string) => ({
                  question_id: item,
                  answer: value[item],
                  solution_id: this.state.solution_id,
                }));
                this.questionsSubmit(answer);
              }}
            >
              {({
                errors,
                touched,
              }: {
                errors: {[key: string]: string;};
                touched: { [key: string]: boolean };
              }) => (
                <Form translate={"yes"}>
                  <Box className="que-form">
                    {this.state.additionalQuestion && this.state.additionalQuestion.length > 0 && this.state.additionalQuestion.map(
                      (
                        item: {
                          id: number;
                          question: string;
                          solution_id: number;
                        },
                        index: number
                      ) => {
                        const queId = item.id.toString();
                        return (
                          <Box className="form-box" key={0 + index}>
                            <Typography className="input-label">
                              {item.question}
                            </Typography>
                            <Field
                              type="text"
                              id={queId}
                              name={queId}
                              className="input-box"
                            />
                            {this.handleError(errors[queId], touched[queId])}
                          </Box>
                        );
                      }
                    )}
                    <Button
                      type="submit"
                      className="submit-btn"
                      data-test-id="details-submit-btn"
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </AdditionalQueWrapper>
    );
  };

  render() {
    const { breadCrumData, openRewardForm } = this.state;

    return (
      // Required for all blocks
      <ReportModelBox className="report-model">
        {this.renderReportModel()}
        {this.handleSucessModel()}
        <Navbar
          // @ts-ignore
          params={"Categories"}
          headerText={
            openRewardForm
              ? "Additional Questions"
              : this.props.history.location.state.headerText
          }
          history={this.props.history}
          contact_us={this.state.contact_us}
        />
        <Box
          className={
            openRewardForm ? "bread-crumb" : "bread-crumb-sec"
          }
        >
          <BreadCrumb
            params={""}
            history={this.props.history}
            breadcrumData={breadCrumData}
          />
        </Box>

        {openRewardForm ? (
          this.rewardsQuestion()
        ) : (
          <Wrapper
      sx={{ width: "90%", margin: "0px auto", marginBottom: "70px" }}
    >
      <Typography className="heading-text" variant="h5">
        {this.state.details.solution_title}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        //justifyContent="space-around"
        bgcolor="#3a324b"
        sx={{
          width:"137px",
          display: "flex",
          padding: "8px",
          borderRadius: "0 10px 10px 0",
          borderLeft: " 4px solid #FF9520",
        }}
      >
        <img src={cubeImage} alt="cubeImg" className="cube-img" />
        <Typography variant="body1" className="font-family">
          {this.state.details.solution_horizon} Now
        </Typography>
      </Box>
      <Hidden only={["xs"]}>
        <Typography variant="body1" className="description">
          {this.state.details.solution_description}
        </Typography>
      </Hidden>
      <Hidden only={["sm", "md", "lg", "xl"]}>
        <Box>
          {!this.state.readMore ? (
            <Typography variant="body1" className="description">
              {this.state.details.solution_description.substring(0, 110) +
                "..."}
            </Typography>
          ) : (
            <Typography variant="body1" className="description">
              {this.state.details.solution_description}
            </Typography>
          )}
          <Typography
            variant="body1"
            className="read-more"
            data-test-id="read-more"
            onClick={this.readMore}
          >
            {this.state.readMore
              ? configJSON.read_less
              : configJSON.read_more}
          </Typography>
        </Box>
      </Hidden>
      <Typography variant="h5" className="earn-title">
        You will earn:
        <img className="star-logo" src={star} alt="star" />
      </Typography>
      <Box
        sx={{ width: { xs: "100%", sm: "80%", md: "40%", lg: "30%" } }}
      >
        {this.state.details.earnings &&
          this.state.details.earnings.length > 0 &&
          this.state.details.earnings.map((item: {
            id: number;
            title: string;
            value: string;
            solution_id: number;
            created_at: string;
            updated_at: string;
          }) => (
            <Box
              key={item.title}
              display="flex"
              justifyContent="space-between"
              sx={{ margin: "30px 0" }}
            >
              <Typography variant="body1" className="first-list">
                {item.title}
              </Typography>
              <Typography variant="body1" className="first-list second-list">
                {item.value}
              </Typography>
            </Box>
          ))}
        <Button
          variant="contained"
          className={this.state.commitToSolutionBtn ? "submit-btn disable-submit-btn":"submit-btn"}
          data-test-id="open-upload-dialog"
          disabled={this.state.commitToSolutionBtn}
          onClick={this.handleClickOpen}
        >
          {this.state.buttonText}
        </Button>
      </Box>
    </Wrapper>
        )}
      </ReportModelBox>
    );
  }
}
